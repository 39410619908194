import { combineReducers } from "@reduxjs/toolkit";

import globalSlice from "./slices/Global";
import { admin } from "./queries/Admin";
import { user } from "./queries/User";
import { staff } from "./queries/Staff";
import { product } from "./queries/Product";
import { cms } from "./queries/CMS";
import { dashboard } from "./queries/Dashboard";
import roleSlice from "./slices/Admin/Role/roleSlice";
import subAdminSlice from "./slices/Admin/Sub-admin/subAdminSlice";
import storeSlice from "./slices/Admin/Store/storeSlice";
import productSlice from "./slices/Admin/Products/productSlice";
import categorySlice from "./slices/Admin/Category/categorySlice";
import tierSlice from "./slices/Admin/Tier/tierSlice";
import ordersSlice from "./slices/Admin/Orders/ordersSlice";
import faqSlice from "./slices/Admin/Faq/faqSlice";
import userSlice from "./slices/User/UserList/userSlice";
import bannerSlice from "./slices/Admin/Banner/bannerSlice";
import orderListingSlice from "./slices/User/UserDetails/orderListingSlice";
import pointHistorySlice from "./slices/User/UserDetails/pointHistorySlice";
import transactionSlice from "./slices/User/UserDetails/transactionSlice";
import staffSlice from "./slices/Staff/staffSlice";
import productAddOnsSlice from "./slices/Admin/Product/productAddOnsSlice";
import productVariantSlice from "./slices/Admin/Product/productVariantSlice";
import contactSlice from "./slices/Admin/Contact/contactSlice";
import activitySlice from "./slices/Admin/Activity/activitySlice";
import transactionListSlice from "./slices/Admin/TransactionsList/transactionListSlice";
import cmsSlice from "./slices/CMS/cmsSlice";
import transactionTypeSlice from "./slices/User/Transactiontype/transactionTypeSlice";
import vehicleListingSlice from "./slices/User/UserDetails/vehicleListingSlice";
import storeListingSlice from "./slices/User/UserDetails/storeListingSlice";
import productListingSlice from "./slices/User/UserDetails/productListingSlice";
import { global } from "./queries/Global";
import customOfferSlice from "./slices/User/CustomOffers/customOfferSlice";

const appReducer = combineReducers({
  [admin.reducerPath]: admin.reducer,
  [user.reducerPath]: user.reducer,
  [staff.reducerPath]: staff.reducer,
  [product.reducerPath]: product.reducer,
  [cms.reducerPath]: cms.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [global.reducerPath]: global.reducer,
  global: globalSlice,
  role: roleSlice,
  subAdmin: subAdminSlice,
  store: storeSlice,
  product: productSlice,
  category: categorySlice,
  tier: tierSlice,
  orders: ordersSlice,
  faq: faqSlice,
  user: userSlice,
  banner: bannerSlice,
  orderListing: orderListingSlice,
  pointHistory: pointHistorySlice,
  transaction: transactionSlice,
  staff: staffSlice,
  productAddOns: productAddOnsSlice,
  productVariant: productVariantSlice,
  contact: contactSlice,
  activity: activitySlice,
  transactionlist: transactionListSlice,
  cms: cmsSlice,
  transactiontype: transactionTypeSlice,
  vehicleListing: vehicleListingSlice,
  storeListing: storeListingSlice,
  productListing: productListingSlice,
  customOffers: customOfferSlice,
});

export default appReducer;
