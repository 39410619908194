import DashboardHero from "../DashboardHero";
import useDashboardListing from "./useDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";

const DashboardListing = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    // children,
    listingType,
    // isSuccess,
  } = useDashboardListing();

  return (
    <div className={Style.dashboard_wrap}>
      {true && true ? (
        <div className={`pro-w-100 pro-my-5`}>
          <DashboardHero
            data={dashboardData?.data}
            obj={dashboardItems}
            loading={isFetching}
            listingType={listingType}
            shimmerCount={3}
          />
        </div>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};
export default DashboardListing;
