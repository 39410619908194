import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Admin/Store/storeSlice";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const useStoreHours = (formik) => {
  const [enabledDays, setEnabledDays] = useState({});
  const dispatch = useDispatch();
  const { isEdit, pickupTimeError } = useSelector((state) => state.store);
  const [time, setTime] = useState({
    from: {},
    to: {},
  });

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Curbside Hours";
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    if (formik.values.pick_up !== 1) {
      formik.setFieldValue("pickup_hours", {});
      dispatch(
        updateConfig((state) => {
          state.pickupTimeError = {};
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.pick_up]);

  useEffect(() => {
    // Extract values from formik and set in the time state
    const extractedTime = {};

    daysOfWeek?.forEach((day) => {
      if (formik?.values?.pickup_hours && formik?.values?.pickup_hours[day]) {
        extractedTime[day] = {
          from: dayjs(formik?.values?.pickup_hours[day]?.from, "h:mm A"),
          to: dayjs(formik?.values?.pickup_hours[day]?.to, "h:mm A"),
        };
      } else {
        extractedTime[day] = {
          from: null,
          to: null,
        };
      }
    });

    setTime(extractedTime);

    // Enable toggles for days with a time, otherwise disable
    const enabledDaysFromExtractedTime = {};
    daysOfWeek.forEach((day) => {
      enabledDaysFromExtractedTime[day] =
        !!extractedTime[day]?.from && !!extractedTime[day]?.to;
    });
    setEnabledDays(enabledDaysFromExtractedTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.pickup_hours]);

  const handleTimeChange = (day, type, newValue) => {
    const updatedTimeState = { ...time };
    updatedTimeState[day][type] = newValue;

    if (updatedTimeState[day].from && updatedTimeState[day].to) {
      const fromTime = updatedTimeState[day].from;
      const toTime = updatedTimeState[day].to;

      // Check if "to" time is at least 1 hour after "from" time
      const timeDifferenceInMinutes = toTime.diff(fromTime, "minutes");
      const isError = timeDifferenceInMinutes < 60;

      // Update the error state for this day
      dispatch(
        updateConfig((state) => {
          state.pickupTimeError = {
            ...state.pickupTimeError,
            [day]: isError
              ? "To time should be at least 1 hour after from time"
              : null,
          };
        })
      );

        const updatedCurbsideHours = {
          ...formik?.values.pickup_hours,
          [day]: {
            from: fromTime.format("h:mm A"),
            to: toTime.format("h:mm A"),
          },
        };

        formik.setFieldValue("pickup_hours", updatedCurbsideHours);
   
    }

    setTime(updatedTimeState);
  };

  const handleToggle = (day) => {
    setEnabledDays((prevEnabledDays) => {
      const updatedEnabledDays = {
        ...prevEnabledDays,
        [day]: !prevEnabledDays[day],
      };

      // Remove day and its time if toggle is disabled
      if (!updatedEnabledDays[day]) {
        const updatedPickupHours = { ...formik?.values.pickup_hours };
        delete updatedPickupHours[day];
        formik.setFieldValue("pickup_hours", updatedPickupHours);
        dispatch(
          updateConfig((state) => {
            state.pickupTimeError = {
              ...state.pickupTimeError,
              [day]: null,
            };
          })
        );
      }

      return updatedEnabledDays;
    });
  };
  const getMinToTime = (day, fromTime) => {
    if (fromTime) {
      return fromTime.add(1, "hour");
    }
    return null;
  };
  return {
    isEdit,
    time,
    handleNext,
    handleCloseModal,
    daysOfWeek,
    handleTimeChange,
    handleToggle,
    enabledDays,
    getMinToTime,
    pickupTimeError,
  };
};

export default useStoreHours;
