import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/CMS/cmsSlice";

const useComponentForm = (basicData, formik) => {
  let selectedOptions;
  const dispatch = useDispatch();
  const { editHomePageData, componentIndex, characters } = useSelector(
    (state) => state.cms
  );

  const categoryList = basicData?.data?.categoryList?.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));
  const bannerList = basicData?.data?.bannerList?.map(({ _id, title }) => ({
    value: _id,
    label: title,
  }));
  const productCategoryList = basicData?.data?.productCategoryList?.map(
    ({ slug, name }) => ({
      value: slug,
      label: name,
    })
  );
  useEffect(() => {
    if (editHomePageData !== "" && editHomePageData) {
      formik.setValues({
        title: editHomePageData?.title ?? "",
        alias_title: editHomePageData?.alias_title ?? "",
        selected_items: editHomePageData?.selected_items ?? [],
      });
    }
    //eslint-disable-next-line
  }, [editHomePageData]);
  switch (editHomePageData?.type) {
    case "banners":
      selectedOptions = bannerList;
      break;
    case "products":
      selectedOptions = productCategoryList;
      break;
    case "home_categories":
      selectedOptions = categoryList;
      break;
    case "single_banner":
      selectedOptions = bannerList;
      break;
    default:
      break;
  }
  const handleSelect = (value) => {
    formik?.setFieldValue("selected_items", value?.value || null);
    let updatedData = characters?.map((character, index) => {
      if (index === componentIndex) {
        return {
          ...character,
          selected_items: value?.value,
        };
      }
      return character;
    });
    dispatch(
      updateConfig((state) => {
        state.characters = updatedData;
      })
    );
  };

  return {
    componentIndex,
    editHomePageData,
    selectedOptions,
    handleSelect,
  };
};
export default useComponentForm;
