import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api/Admin";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}filter=${params?.filter || "all"}&${getParams("sort")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || ""
          }&${getParams("type")}&${getParams("store_name")}&${getParams(
            "payment_mode"
          )}&${getParams("status")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const admin = createApi({
  reducerPath: "adminApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Roles",
    "Store",
    "Admin User",
    "Admin",
    "Tier",
    "Category",
    "Orders",
    "Order Count",
    "Faq",
    "Banner",
    "Contact",
    "Activity",
    "Transactions",
    "Admin Basic Data",
    "Order Basic Data",
  ],
  endpoints: (builder) => ({
    //role rtk query starts
    getRoleListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/role/index",
      }),
      providesTags: ["Roles"],
    }),
    updateRoleTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Roles"],
    }),
    //role rtk query ends

    //Admin user section
    getSubAdminListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/admin/index",
      }),
      providesTags: ["Admin"],
    }),
    getSubAdminBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/admin/basic-data",
      }),
      providesTags: ["Admin Basic Data"],
    }),
    updateSubAdminTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Admin"],
    }),
    updateSubAdminStatus: builder.mutation({
      query: (params) => ({
        method: "post",
        endpoint: `admin/change-status?user_id=${params.user_id}&status=${params.status}`,
      }),
    }),

    //Admin user section ends

    //Store section starts
    getStoreListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/store/index",
      }),
      providesTags: ["Store"],
    }),
    updateStoreTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Store"],
    }),
    updateStoreStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `store/change-status`,
      }),
    }),
    // fetchMenu: builder.mutation({
    //   query: (body) => ({
    //     method: "post",
    //     body,
    //     endpoint: "/product/fetch-menu",
    //   }),
    //   invalidatesTags: ["Store"],
    // }),

    //Store section ends
    //Admin Tier section Starts
    getTierListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/tier/index",
      }),
      providesTags: ["Tier"],
    }),
    updataTierHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Tier"],
    }),
    //Admin Tier section Ends
    //Category section starts

    getCategoryListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/category/index",
      }),
      providesTags: ["Category"],
    }),
    updateCategoryTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Category"],
    }),
    getProfileImage: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/category/upload-image`,
      }),
    }),
    getIconImage: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/category/upload-image`,
      }),
    }),

    //Category section ends

    //Orders section starts

    getOrdersListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/admin/order-list",
        keepUnusedDataFor: 120,
      }),
      providesTags: ["Orders"],
    }),
    updateOrdersTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),
      invalidatesTags: ["Orders"],
    }),
    getOrderCountData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/admin/order-count",
        keepUnusedDataFor: 120,
      }),
      providesTags: ["Order Count"],
    }),
    getFilterBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/admin/filter-data",
      }),
      providesTags: ["Order Basic Data"],
    }),
    //Orders section eds
    //Faq section starts

    getFaqListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/faq/list",
      }),
      providesTags: ["Faq"],
    }),
    updateFaqTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Faq"],
    }),
    updateFaqStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `faq/status-change`,
      }),
    }),
    //Faq section ends

    //Banner section starts
    getBannerListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/banner/index",
      }),
      providesTags: ["Banner"],
    }),
    updateBannerHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),
      invalidatesTags: ["Banner"],
    }),
    updateBannerStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `banner/change-status`,
      }),
    }),
    getBannerBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/staff/basic-data",
      }),
      providesTags: ["Banner"],
    }),
    //Banner section ends
    //Contact us section
    getContactListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/contact/index",
      }),
      providesTags: ["Contact"],
    }),
    updateContactTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),
      invalidatesTags: ["Contact"],
    }),
    //Contact Us section ends
    //Transactions section starts
    getTransactionListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/wallet-transaction/index",
      }),
      providesTags: ["Transactions"],
    }),
    updateTransactionTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),
      invalidatesTags: ["Transactions"],
    }),

    getActivityLogListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/activity-log/index",
      }),
      providesTags: ["Activity"],
    }),
    updateActivityTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/user-field/store",
      }),
      invalidatesTags: ["Activity"],
    }),
  }),
});

export const {
  useGetContactListDataQuery,
  useGetActivityLogListDataQuery,
  useUpdateContactTableHeadDataMutation,
  useGetRoleListDataQuery,
  useGetSubAdminBasicDataQuery,
  useGetSubAdminListDataQuery,
  useGetStoreListDataQuery,
  useGetOrdersListDataQuery,
  useGetCategoryListDataQuery,
  useGetFaqListDataQuery,
  useGetProfileImageMutation,
  useGetIconImageMutation,
  useUpdateFaqTableHeadDataMutation,
  useUpdateOrdersTableHeadDataMutation,
  useUpdateCategoryTableHeadDataMutation,
  useUpdateStoreTableHeadDataMutation,
  useUpdateSubAdminStatusMutation,
  useUpdateStoreStatusMutation,
  useUpdateRoleTableHeadDataMutation,
  useUpdateSubAdminTableHeadDataMutation,
  useUpdateBannerHeadDataMutation,
  useGetTierListDataQuery,
  useUpdateBannerStatusMutation,
  useUpdataTierHeadDataMutation,
  useGetBannerListDataQuery,
  useGetTransactionListDataQuery,
  useUpdateTransactionTableHeadDataMutation,
  useGetBannerBasicDataQuery,
  useUpdateFaqStatusMutation,
  useUpdateActivityTableHeadDataMutation,
  useGetOrderCountDataQuery,
  useGetFilterBasicDataQuery,
  // useFetchMenuMutation,
} = admin;
