import React, { useMemo } from "react";
import Dashboard from "../components/Dashboard";
import { useSelector } from "react-redux";
import Roles from "../components/Admin/Roles";
import User from "../components/User";
import AdminUser from "../components/Admin/AdminUser";
import Store from "../components/Admin/Store";
import Category from "../components/Admin/Category";
import Tier from "../components/Admin/Tier";
import OrderList from "../components/Admin/OrderList";
import Faq from "../components/User/Faq";
import Staff from "../components/Staff";
import ActivityLog from "../components/Admin/ActivityLog";
import ContactUs from "../components/User/ContactUs";
import CMS from "../components/CMS";
import TransactionType from "../components/User/TransactionType";
import Banner from "../components/User/Banner";
import Transactions from "../components/User/UserDetailView/Transactions";
import Appearance from "../components/Admin/Settings/Appearance";
import HomePage from "../components/CMS/HomePage";
import VideoContent from "../components/CMS/VideoContent";
import CustomOffers from "../components/User/CustomOffers";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];
  const AdminComponent = [
    {
      component: <Roles />,
      permission: "Roles",
      path: "roles",
    },
    {
      component: <AdminUser />,
      permission: "Admin",
      path: "admin-user",
    },
    {
      component: <Store />,
      permission: "Store",
      path: "store",
    },
    {
      component: <Category />,
      permission: "Category",
      path: "category",
    },
    {
      component: <Tier />,
      permission: "Tier",
      path: "tier",
    },
    {
      component: <OrderList />,
      permission: "Orders",
      path: "orders",
    },
    {
      component: <ActivityLog />,
      permission: "Activity Log",
      path: "activity",
    },
    {
      component: <Appearance />,
      permission: "Settings",
      path: "settings",
    },
  ];
  const UserComponent = [
    {
      component: <User />,
      permission: "User",
      path: "users",
    },
    {
      component: <Banner />,
      permission: "Banner",
      path: "banner",
    },
    {
      component: <ContactUs />,
      permission: "Contact Us",
      path: "contact-us",
    },
    {
      component: <Faq />,
      permission: "FAQ",
      path: "faq",
    },
    {
      component: <TransactionType />,
      permission: "Transaction Types ",
      path: "transaction-type",
    },
    {
      component: <Transactions />,
      permission: "Transactions ",
      path: "transactions",
    },
    {
      component: <CustomOffers />,
      permission: "Offers ",
      path: "offers",
    },
  ];
  const StaffComponent = [
    {
      component: <Staff />,
      permission: "Staff",
      path: "list",
    },
  ];
  const ContentComponent = [
    {
      component: <CMS />,
      permission: "CMS",
      path: "policies-terms",
    },
    {
      component: <HomePage />,
      permission: "Home",
      path: "home-page",
    },
    {
      component: <VideoContent />,
      permission: "Video Content",
      path: "video",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState?.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap((menu) => menu?.menu_name);

    if (!menus?.length) {
      menus = [];
    }
    menus = [...menus];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent?.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedAdminComponent = AdminComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedUserComponent = UserComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedStaffComponent = StaffComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedContentComponent = ContentComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );

  return {
    allowedDashboardComponent,
    allowedContentComponent,
    allowedStaffComponent,
    allowedAdminComponent,
    allowedUserComponent,
  };
};

export default useRoutes;
