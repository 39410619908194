import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  editTierData: {},
  isLoading: false,
  error: "",
};

export const getTierEditData = createAsyncThunk(
  "/admin/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/tier/edit?tier_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tierSlice = createSlice({
  name: "tier",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTierEditData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTierEditData.fulfilled, (state, action) => {
        state.editTierData = action.payload?.data;
      })
      .addCase(getTierEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default tierSlice.reducer;
export const { updateConfig } = tierSlice.actions;
