import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddBanner from "./useAddBanner";
// import { capitalizeOnSpace } from "../../../../utils/Functions/table";

const AddBanner = ({ isStickyFooter, handleCreateClick, refetch }) => {
  const {
    formik,
    isEdit,
    isLoading,
    selectVal,
    storeVal,
    imagePreview,
    imageName,
    arabicImagePreview,
    arabicImageName,
    closeModal,
    isLoadingImage,
    isArabicImageLoading,
    basicData,
    bannerDatas,
    getFieldError,
    handleImageChange,
  } = useAddBanner(handleCreateClick, refetch);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Title"}
          type="text"
          id="title"
          name="title"
          className={`pro-input lg ${getFieldError("title") && " error"}`}
          {...formik.getFieldProps("title")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("title")}
          errorMessage={getFieldError("title")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type
          </label>
          <Select
            id="type"
            name="type"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("type") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData ?? []}
            value={selectVal}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("type")}
            onChange={(e) => formik?.setFieldValue("type", e?.value || null)}
            menuPlacement="auto"
          />
          {getFieldError("type") && (
            <span className="error-text">{getFieldError("type")}</span>
          )}
        </div>

        {formik?.values.type === "store" && (
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Store
            </label>
            <Select
              id="customer_key"
              name="customer_key"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("customer_key") && " error"
              }`}
              classNamePrefix="pro-input"
              options={bannerDatas ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={storeVal}
              onBlur={formik.handleBlur("customer_key")}
              onChange={(value) =>
                formik?.setFieldValue("customer_key", value?._id || null)
              }
              menuPlacement="auto"
            />
            {getFieldError("customer_key") && (
              <span className="error-text">
                {getFieldError("customer_key")}
              </span>
            )}
          </div>
        )}
        <div className="input-wrap pro-mb-4 ">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.image_url && formik.touched.image_url && " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("image_url")}
              onChange={(e) => handleImageChange(e, "image")}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  isLoadingImage
                    ? "Uploading..."
                    : imageName !== ""
                    ? imageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.image_url && formik.errors.image_url && (
              <span className="error-text">{formik.errors.image_url}</span>
            )}
          </div>
          <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={imagePreview}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
        <div className="input-wrap pro-mb-4 pro-rtl">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            ( صورة ) Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.alias_image_url &&
                formik.touched.alias_image_url &&
                " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("alias_image_url")}
              onChange={(e) => handleImageChange(e, "alias_image")}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  isArabicImageLoading
                    ? "...Uploading"
                    : arabicImageName !== ""
                    ? arabicImageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.alias_image_url &&
              formik.errors.alias_image_url && (
                <span className="error-text">
                  {formik.errors.alias_image_url}
                </span>
              )}
          </div>
          <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={arabicImagePreview}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>

        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            disabled={isLoading}
            onClick={formik.handleSubmit}
          >
            {!isEdit
              ? isLoading
                ? "createing..."
                : "Create"
              : isLoading
              ? "Updating"
              : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
