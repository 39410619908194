import { useEffect, useState } from "react";
import { useGetOrderCountDataQuery } from "../../../../store/queries/Admin";
import { useSelector } from "react-redux";

const useOrderCardListing = () => {
  const ordersState = useSelector((state) => state.orders);
  const {
    data: orderCountData = {},
    isFetching,
    isSuccess,
  } = useGetOrderCountDataQuery({
    store_name: ordersState?.store_name,
    payment_mode: ordersState?.payment_mode,
    status: ordersState?.status,
  });
  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(orderCountData?.data || {})?.[0] ?? "");
    }

    //eslint-disable-next-line
  }, [isSuccess]);
  const handleYearlyOrders = () => {
    setListingType("yearly_orders");
  };
  const handleMonthlyOrders = () => {
    setListingType("monthly_orders");
  };
  const handleWeeklyTransaction = () => {
    setListingType("weekly_orders");
  };
  const handleDailyTransaction = () => {
    setListingType("daily_orders");
  };
  let object = {
    yearly_orders: handleYearlyOrders,
    monthly_orders: handleMonthlyOrders,
    weekly_orders: handleWeeklyTransaction,
    daily_orders: handleDailyTransaction,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let orderCardItems = {
    yearly_orders: {
      label: "yearly_orders",
      title: "Yearly Orders",
      icon: "playlist_add_check",
      handleClick,
      // component: <Messages dashboard={true} />,
    },
    monthly_orders: {
      label: "monthly_orders",
      title: "Monthly Orders",
      icon: "list_alt_add",
      handleClick,
      // component: <Members dashboard={true} />,
    },
    weekly_orders: {
      label: "weekly_orders",
      title: "Weekly Orders",
      icon: "paid",
      handleClick,
      // component: <Deleted dashboard={true} />,
    },
    daily_orders: {
      label: "daily_orders",
      title: "Daily Orders",
      icon: "paid",
      handleClick,
      // component: <Deleted dashboard={true} />,
    },
  };

  return {
    isSuccess,
    isFetching,
    listingType,
    orderCardItems,
    orderCountData,
    children: orderCardItems[listingType]?.component,
  };
};

export default useOrderCardListing;
