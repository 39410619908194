import { useDispatch, useSelector } from "react-redux";
import {
  useGetTransactionListDataQuery,
  useUpdateTransactionTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { updateConfig } from "../../../store/slices/Admin/TransactionsList/transactionListSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect } from "react";

const useTransactionLists = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const transactionsState = useSelector((state) => state.transactionlist);
  const {
    data: transactionList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetTransactionListDataQuery({
    page_size: transactionsState?.currentPageSize,
    page: transactionsState?.currentPage,
    sort: transactionsState?.sortBy,
    sort_order: transactionsState?.sortOrder,
    search: transactionsState?.search,
  });
  const [updatetransactionFields] = useUpdateTransactionTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  useEffect(() => {
    if (transactionList && transactionList?.data?.total_count) {
      dispatch(
        updateConfig((state) => {
          state.totalOrderCount = transactionList?.data?.total_count ?? "0";
        })
      );
    }
    //eslint-disable-next-line
  }, [transactionList?.data?.total_count]);

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (transactionsState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            transactionsState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    transactionList,
    tableFields,
    transactionsState,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    updatetransactionFields,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useTransactionLists;
