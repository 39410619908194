import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";

const useSendExcelData = (sendExcelData) => {
  const { emailRegExp } = useValidations();
  const validation = Yup.object({
    email: Yup.string()
      .trim()
      .required("Enter  Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (values?.email) {
        sendExcelData(values);
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { formik, getFieldError };
};

export default useSendExcelData;
