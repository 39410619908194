import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../../store/slices/Admin/Products/productSlice";
import { useSelector } from "react-redux";
import { useGetProductImageMutation } from "../../../../../../store/queries/Product";

const useProductVariants = (formik, data, productBasicData) => {
  const dispatch = useDispatch();

  const { variantImage } = useSelector((state) => state.product);
  const [selectProductImage] = useGetProductImageMutation();

  const handleImageVariantCustomizedPreviewChange = (event, index) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      selectProductImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            `variants.${index}.image_url`,
            response?.data?.data?.image_path
          );
        }
      });
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.variantImage[index].image = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageVariantCustomizedPreviewRemove = (index) => {
    dispatch(
      updateConfig((state) => {
        state.variantImage[index].image = "";
      })
    );
    formik.setFieldValue(`variants.${index}.image_url`, "");
    formik?.setFieldError(`variants.${index}.image_url`, "Upload image");
    formik?.setFieldTouched(`variants.${index}.image_url`, true);
  };
  const handleImageVariantPreviewChange = (event, index) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      selectProductImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            `variants.${index}.add_on_image`,
            response?.data?.data?.image_path
          );
        }
      });

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.variantImage[index].add_on_image = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageVariantPreviewRemove = (index) => {
    dispatch(
      updateConfig((state) => {
        state.variantImage[index].add_on_image = "";
      })
    );
    formik.setFieldValue(`variants.${index}.add_on_image`, "");
    formik?.setFieldError(`variants.${index}.add_on_image`, "Upload image");
    formik?.setFieldTouched(`variants.${index}.add_on_image`, true);
  };

  return {
    variantImage,
    handleImageVariantPreviewChange,
    handleImageVariantPreviewRemove,
    handleImageVariantCustomizedPreviewChange,
    handleImageVariantCustomizedPreviewRemove,
  };
};

export default useProductVariants;
