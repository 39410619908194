import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import AddTier from "./AddTier";
import useTier from "./useTier";

const Tier = () => {
  const {
    tierList,
    tierState,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    handleCreateClick,
    handleEditColumnsClick,
    handlePagination,
    handleEditAction,
    handleDeleteModal,
    updateTierFields,
    handleClearClick,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useTier();

  return (
    <>
      <HeadingGroup
        title="All Tier"
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: tierState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        <Table
          multiSelect={false}
          data={tierList?.data?.result || []}
          uniqueID={"id"}
          fields={tierList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={tierState?.clearSelection}
          perpage={tierState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={true}
          showCheckBox={true}
        />
        {tierList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={tierState?.currentPage}
            totalPageCount={Math.ceil(
              tierList.data.total_count / tierState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={tierState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Tier ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={tierList?.data?.fields || []}
              moduleId={tierList?.data?.module_id}
              updateData={updateTierFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={tierState?.showCreateModal}
          handleClose={handleCreateClick}
          title={tierState?.isEdit ? "Update Tier" : "Add Tier"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <AddTier
            refetch={refetch}
            tierState={tierState}
            isStickyFooter
            handleClose={handleCreateClick}
          />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Tier;
