import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Banner/bannerSlice";
import { toast } from "react-toastify";
import { getBannerImage, createBanner } from "../api";
import { useGetBannerBasicDataQuery } from "../../../../store/queries/Admin";

const useAddBanner = (handleCreateClick, refetch) => {
  const dispatch = useDispatch();
  const { data: bannerData = {} } = useGetBannerBasicDataQuery();

  const {
    isEdit,
    imagePreview,
    imageName,
    arabicImagePreview,
    arabicImageName,
    editBannerData,
  } = useSelector((state) => state.banner);

  const basicData = [
    { value: "product", label: "Product", _id: 1 },
    { value: "store", label: "Store", _id: 2 },
  ];
  const { isLoadingImage, isLoading, isArabicImageLoading } = useSelector(
    (state) => state.banner
  );
  const validation = Yup.object({
    title: Yup.string()
      .trim()
      .required("Enter Banner Title")
      .max(50, "Enter valid First Name"),
    image_url: Yup.string().required("Please upload an image"),
    alias_image_url: Yup.string().required("Please upload an image"),
    type: Yup.string().required("Select type"),
    customer_key: Yup.string().test(
      "BannerStore",
      "Select a store",
      function (value) {
        const { type } = this.parent;
        if (type === "store") {
          return !!value;
        }
        return true;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      image_url: "",
      alias_image_url: "",
      status: 2,
      type: "",
      customer_key: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      dispatch(
        updateConfig((state) => {
          state.isLoading = true;
        })
      );

      if (!isEdit) {
        createBanner(subData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Banner created successfully`);
            refetch();
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            dispatch(
              updateConfig((state) => {
                state.isLoading = false;
              })
            );
          } else {
            toast.error(`Something went wrong!`);
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          }
        });
      } else {
        let updateBannerData = { banner_id: editBannerData?._id, ...subData };

        createBanner(updateBannerData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Banner updated successfully`);
            refetch();
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            dispatch(
              updateConfig((state) => {
                state.isLoading = false;
              })
            );
          } else {
            toast.error(`Something went wrong!`);
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  const handleImageChange = (event, fieldName) => {
    const selectedLogo = event?.target?.files[0];
    // for conditional Loading of the Images
    const isMainImage = fieldName === "image";
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      dispatch(
        updateConfig((state) => {
          isMainImage
            ? (state.isLoadingImage = true)
            : (state.isArabicImageLoading = true);
        })
      );
      getBannerImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              isMainImage
                ? (state.isLoadingImage = false)
                : (state.isArabicImageLoading = false);
            })
          );
          formik.setFieldValue(
            fieldName === "image" ? "image_url" : "alias_image_url",
            response?.data?.data?.image_path
          );
        }
      });
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state[
              fieldName === "image" ? "imagePreview" : "arabicImagePreview"
            ] = reader.result;
            state[fieldName === "image" ? "imageName" : "arabicImageName"] =
              selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  let selectVal = basicData.find((item) => item.value === formik?.values?.type);
  let storeVal = bannerData?.data?.stores?.find(
    (item) => item?._id === formik?.values?.customer_key
  );
  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        title: editBannerData?.title || "",
        status: editBannerData?.status || 2,
        type: editBannerData?.type || "",
        image_url: editBannerData?.image_url,
        alias_image_url: editBannerData?.alias_image_url,
        customer_key: editBannerData?.customer_key || "",
      });
      dispatch(
        updateConfig((state) => {
          state.imagePreview = editBannerData?.image_full_url;
          state.imageName = editBannerData?.image_url?.split("/").pop();
          state.arabicImagePreview = editBannerData?.alias_image_full_url;
          state.arabicImageName = editBannerData?.alias_image_url
            ?.split("/")
            .pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, editBannerData]);

  return {
    formik,
    selectVal,
    storeVal,
    isEdit,
    imagePreview,
    imageName,
    arabicImagePreview,
    arabicImageName,
    closeModal,
    getFieldError,
    basicData,
    isLoading,
    handleImageChange,
    isLoadingImage,
    isArabicImageLoading,
    bannerDatas: bannerData?.data?.stores,
  };
};

export default useAddBanner;
