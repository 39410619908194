import React, { useEffect, useState } from "react";
import { updateConfig } from "../../../store/slices/User/UserList/userSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetUserListDataQuery,
  useUpdateUserTableHeadDataMutation,
} from "../../../store/queries/User";
import { useSelector } from "react-redux";
import Style from "./user.module.scss";
import { useDispatch } from "react-redux";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import Assets from "../../../assets/Assets";
import { deleteUser, getUserExportedData } from "./api";
import { toast } from "react-toastify";
import { getFormatedDate } from "../../../utils/Functions/table";
import { subDays } from "date-fns";

const useUserList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const [isUserExcelLinkSend, setIsUserExcelLinkSend] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: userList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetUserListDataQuery({
    page_size: userState?.currentPageSize,
    page: userState?.currentPage,
    sort: userState?.sortBy,
    start_date: getFormatedDate(userState.startDate),
    end_date: getFormatedDate(userState.endDate),
    sort_order: userState?.sortOrder,
    search: userState?.search,
  });
  const [updateUserFields] = useUpdateUserTableHeadDataMutation();

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  useEffect(() => {
    if (userState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [userState.clearSelection]);
  useEffect(() => {
    if (userList && userList?.data?.total_count) {
      dispatch(
        updateConfig((state) => {
          state.totalOrderCount = userList?.data?.total_count ?? "0";
        })
      );
    }
    //eslint-disable-next-line
  }, [userList?.data?.total_count]);
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  // const handleClearClick = () => {
  //   dispatch(updateConfig((state) => (state.search = "")));
  // };
  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
    setIsDateRangeApplied(false);
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      full_name: (field, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
              onClick={() => handleViewUser(data)}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={
                    data?.image_url !== ""
                      ? data?.image_url
                      : Assets.GENDER_IMAGE
                  }
                  width={16}
                  height={16}
                  alt={data?.full_name}
                />
              </div>
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
              >
                {data?.full_name}
              </span>
            </div>
          </>
        );
      },
      status: (field, data) =>
        data?.status === "Active" ? (
          <span className={"pro-badge badge-primary-outline"}>{"Active"}</span>
        ) : data?.status === "Deactivated" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : (
          data?.status
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleViewUser = (data) => {
    localStorage.setItem("userDetailsData", JSON.stringify(data));
    navigate(`/user-profile/${data?.id}`);
    sessionStorage.setItem("active", `${data?.id}`);
  };
  const handleSort = (label) => {
    if (userState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = userState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDelete = () => {
    //TODO
    let userId = userState?.selectedId;
    deleteUser(userId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.showDeleteModal = false;
            state.clearSelection = true;
          })
        );
        toast.success("User Deleted");
        refetch();
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const [date, setDate] = useState({
    startDate: userState.startDate,
    endDate: userState.endDate,
    key: "selection",
  });
  const handleDateChange = (range) => {
    setIsDateRangeApplied(true);
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    setIsDateRangeApplied(true);
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const handleExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.excelSendModal = !state.excelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.excelSendModal = false;
      })
    );
  };
  const sendUserExcelDownload = async (value) => {
    setIsUserExcelLinkSend(true);
    let params = {
      start_date: getFormatedDate(userState.startDate),
      end_date: getFormatedDate(userState.endDate),
      sort_order: userState?.sortOrder,
      sort: userState?.sortBy,
      search: userState?.search,
      email: value?.email ?? "",
    };
    let response = await getUserExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error(`Failed to send link:`, error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsUserExcelLinkSend(false);
    }
  };

  return {
    date,
    userList,
    userState,
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    isDateRangeApplied,
    isUserExcelLinkSend,
    handleEditColumnsClick,
    sendUserExcelDownload,
    handleDateRangeChange,
    handleExcelDownload,
    updateUserFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    handleDateChange,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useUserList;
