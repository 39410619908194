import React from "react";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import useProductChangeForm from "./useProductChangeForm";
import ActualDetails from "./ActualDetails";
import FetchedDetails from "./FetchedDetails";
import Style from "../product.module.scss";
import ProductEditShimmer from "../../../Global/Shimmers/ProductEditShimmer";

const ProductChangeForm = ({ isStickyFooter, refetch }) => {
  const {
    addOnKey,
    finalData,
    isSuccess,
    newKeyData,
    variantsKey,
    newFetchedData,
    updateAllStore,
    optionalAddOnKey,
    previousFetchedData,
    isSavingChangedProduct,
    handleCloseModal,
    handleSubmit,
    handleUpdateAllStore,
  } = useProductChangeForm(refetch);

  return (
    <>
      <div className="pro-px-5 pro-pb-5">
        <div className={`row ${Style.main_wrapper}`}>
          <div className={`col-md-6 ${Style.first_box}`}>
            {isSuccess ? (
              <ActualDetails data={previousFetchedData} />
            ) : (
              <div className={`app-loading ${Style.loader}`}></div>
            )}
          </div>
          <div className="col-md-6">
            {isSuccess ? (
              <FetchedDetails
                data={newFetchedData}
                prevData={previousFetchedData}
                missMatchedKeyData={newKeyData}
                addOnKey={addOnKey}
                optionalAddOnKey={optionalAddOnKey}
                variantsKey={variantsKey}
                finalData={finalData}
              />
            ) : (
              <div className={`app-loading ${Style.loader}`}></div>
            )}
          </div>
        </div>
      </div>
      {isSuccess ? (
        <div className={`col-12 pro-px-5 pro-py-4 pro-bg-light pro-border-top`}>
          <div className="pro-px-5 pro-w-100 pro-d-flex pro-justify-end pro-items-center">
            <div className="pro-d-flex pro-items-center pro-pe-6 pro-me-6">
              <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                <input
                  type="checkbox"
                  className="pro-check"
                  id="update_all_store"
                  name="update_all_store"
                  checked={updateAllStore}
                  onChange={handleUpdateAllStore}
                />
              </div>
              <label
                htmlFor="update_all_store"
                className="pro-font-sm pro-mb-0 pro-fw-medium"
              >
                Update All Store
              </label>
            </div>
            <Button
              className={"pro-btn-link pro-py-3 pro-px-5"}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>

            <Button
              className={`pro-btn-primary pro-ms-3 pro-py-3 pro-px-5 ${
                false ? "loading" : ""
              }`}
              type="submit"
              onClick={handleSubmit}
              disabled={isSavingChangedProduct}
            >
              {`${isSavingChangedProduct ? "Saving" : "Save"}`}
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductChangeForm;
