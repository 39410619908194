import React from "react";
import useOrderCardListing from "./useOrderCardListing";
import DashboardHero from "../../../Dashboard/DashboardHero";
import EmptyData from "../../../Global/EmptyData";

const OrderCardListing = () => {
  const { isFetching, listingType, orderCardItems, orderCountData } =
    useOrderCardListing();
  return (
    <div>
      {true && true ? (
        <div className={`pro-w-100 pro-my-5`}>
          <DashboardHero
            data={orderCountData?.data}
            obj={orderCardItems}
            loading={isFetching}
            listingType={listingType}
          />
        </div>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default OrderCardListing;
