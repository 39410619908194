import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../api/Admin";

const initialState = {
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  showEditModal: false,
  showDeleteModal: false,
  isProfileViewVisible: false,
  menuDrawer: {},
  isLogged: false,
  otpReceived: false,
  currentPage: 1,
  otpGenerated: "",
  tableFields: {},
  searchData: {},
  searchStatus: "idle",
  searchKey: "",
  drawerTheme: "expanded",
  currentTheme: "light",
  isNewNotification: false,
  selectedId: "",
  offCanvasWidth: false,
  offCanvasEditWidth: false,
};

export const getMenuPermissions = createAsyncThunk(
  "/menu-list",
  async (body, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/menu-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });
  },
});

export const { updateConfig } = globalSlice.actions;
export default globalSlice.reducer;
