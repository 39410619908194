import axios from "axios";

let BASE_URL;
switch (process.env.REACT_APP_ENV) {
  case "PRODUCTION":
    BASE_URL = process.env.REACT_APP_AUTH_PRODURL;
    break;
  case "QA":
    BASE_URL = process.env.REACT_APP_AUTH_QA_URL;
    break;
  case "WEBC":
    BASE_URL = process.env.REACT_APP_AUTH_APIURL;
    break;
  default:
    BASE_URL = process.env.REACT_APP_AUTH_APIURL;
    break;
}
let productionApiKey = "zgtJbte1IdbjPFDalLxz6ZQcxfCrnNwp";
let webcApiKey =
  "VVRCR1UxTlZTbEJXVmpsRVZEQmFSMUpWVm1aUlZsWlZVMFk1VkZKV1NsZFRWVTVHV0RCR1VWTldPVlZVTUhSR1ZHYzlQUT09";

let token = "";

export const getAuthAxiosInstance = async () => {
  try {
    token = localStorage.getItem("USER_ACCESS_TOKEN_CARIBOU");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Api-Key":
          process.env.REACT_APP_ENV === "PRODUCTION"
            ? productionApiKey
            : webcApiKey,
      },
    });
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response) {
          if (error?.response?.status === 401) {
            localStorage.clear("USER_ACCESS_TOKEN_CARIBOU");
            localStorage.clear("dashboard_permissions");
            localStorage.clear("productDetailsData");
            localStorage.clear("shopData");
            localStorage.clear("USER_DETAILS");
            window.location.href = "/login";
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
