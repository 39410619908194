import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import useStoreHours from "./useStoreHours";
import Style from "../addStore.module.scss";

const StoreHours = ({ formik, getFieldError }) => {
  const {
    time,
    handleNext,
    handleCloseModal,
    handleTimeChange,
    daysOfWeek,
    handleToggle,
    enabledDays,
    getMinToTime,
    pickupTimeError,
  } = useStoreHours(formik);

  return (
    <>
      <div className="form-check form-switch pro-mb-4">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          role="switch"
          id="pick_up"
          name="pick_up"
          checked={Boolean(formik.values?.pick_up === 1) ?? false}
          onChange={(e) => {
            formik.setFieldValue("pick_up", e.target.checked ? 1 : 2);
          }}
        />
        <label htmlFor="pick_up" className="pro-check-label">
          Set Pickup Hour
        </label>
        {getFieldError("pick_up") && (
          <span className="error">Please select at least one day's hours.</span>
        )}
      </div>

      {formik.values?.pick_up === 1 && (
        <>
          {daysOfWeek.map((day) => (
            <div
              key={day}
              className={`pro-d-flex pro-flex-column pro-mb-4 ${Style.time_wrap}`}
            >
              <label className="pro-font-sm pro-mb-1 pro-fw-medium">
                {day}
              </label>
              <div
                className={`pro-d-flex pro-items-center ${Style.time_picker_wrap}`}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={time[day]?.from || ""}
                    onChange={(newValue) =>
                      handleTimeChange(day, "from", newValue)
                    }
                    className="time-picker"
                    disabled={formik.values.pick_up === 0 || !enabledDays[day]}
                  />
                  <span className="pro-px-3 pro-d-flex pro-items-center">
                    <span className="material-symbols-outlined x4">
                      {" "}
                      remove{" "}
                    </span>
                  </span>

                  <TimePicker
                    value={time[day]?.to || ""}
                    onChange={(newValue) =>
                      handleTimeChange(day, "to", newValue)
                    }
                    className="time-picker"
                    minTime={getMinToTime(day, time[day]?.from)}
                    disabled={formik.values.pick_up === 0 || !enabledDays[day]}
                  />
                  <div className="form-check form-switch pro-mb-0 pro-ms-auto">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id={`${day}-switch`}
                      checked={enabledDays[day] || false}
                      onChange={() => handleToggle(day)}
                    />
                  </div>
                </LocalizationProvider>
              </div>
              {pickupTimeError[day] !== null && (
                <span className="error pro-mt-2 pro-font-xs pro-lh-1">
                  {pickupTimeError[day]}
                </span>
              )}
            </div>
          ))}
        </>
      )}
      <div className={`col-12 pro-d-flex pro-justify-end pro-mt-auto pro-pb-5`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button className={"pro-btn-primary lg pro-ms-3"} onClick={handleNext}>
          {"Next"}
        </Button>
      </div>
    </>
  );
};

export default StoreHours;
