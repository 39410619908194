import React from "react";
import CommonLayout from "../../pages/Layout/CommonLayout";
import { Outlet } from "react-router-dom";
import useDashboard from "./useDashboard";

const Dashboard = () => {
  const drawerMenu = useDashboard();

  return (
    <CommonLayout drawerMenu={drawerMenu?.drawerMenu} isDashboard>
      <Outlet />
    </CommonLayout>
  );
};

export default Dashboard;
