import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
  Navigate,
} from "react-router-dom";
import AuthModule from "../components/Auth";
import Login from "../components/Auth/Login";
import Index from "../pages/Layout";
import Dashboard from "../components/Dashboard";
import ForgotPassword from "../components/Auth/ForgotPassword";
import DashboardListing from "../components/Dashboard/DashboardListing";
import Admin from "../components/Admin";
import Roles from "../components/Admin/Roles";
import User from "../components/User";
import UsersList from "../components/User/UsersList";
import ChangePassword from "../components/Auth/ChangePassword";
import AddRole from "../components/Admin/Roles/AddRole";
import AdminUser from "../components/Admin/AdminUser";
import Store from "../components/Admin/Store";
import Products from "../components/Products";
import Product from "../components/Products/Product";
import Category from "../components/Admin/Category";
import Tier from "../components/Admin/Tier";
import OrderList from "../components/Admin/OrderList";
import Faq from "../components/User/Faq";
import Banner from "../components/User/Banner";
import UserDetailLayout from "../components/User/UserDetailLayout";
import UserDetailView from "../components/User/UserDetailView";
import Staff from "../components/Staff";
import StaffList from "../components/Staff/SatffList";
import ActivityLog from "../components/Admin/ActivityLog";
import ContactUs from "../components/User/ContactUs";
import ProductDetailLayout from "../components/Products/Product/ProductDetailLayout";
import ProductDetailView from "../components/Products/Product/ProductDetailView";
import Appearance from "../components/Admin/Settings/Appearance";
import useTheme from "../components/Admin/Settings/Appearance/useTheme";
import TransactionLists from "../components/User/TransactionLists";
import CMS from "../components/CMS";
import ContentList from "../components/CMS/ContentList";
import TransactionType from "../components/User/TransactionType";
import useDrawerTheme from "../components/Admin/Settings/Appearance/useDrawerTheme";
import HomePage from "../components/CMS/HomePage";
import VideoContent from "../components/CMS/VideoContent";
import CustomOffers from "../components/User/CustomOffers";

const Routes = () => {
  useTheme("light");
  useDrawerTheme("expanded");
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="/admin" element={<Admin />}>
          {/* <Route path="" element={<Roles />} /> */}
          <Route path="roles" element={<Roles />} />
          <Route path="roles/role-update" element={<AddRole />} />
          <Route path="admin-user" element={<AdminUser />} />
          <Route path="store" element={<Store />} />
          <Route path="category" element={<Category />} />
          <Route path="tier" element={<Tier />} />
          <Route path="orders" element={<OrderList />} />
          <Route path="activity" element={<ActivityLog />} />
          <Route path="settings" element={<Appearance />} />
        </Route>
        <Route path="/admin/store/product-list" element={<Products />}>
          <Route path="" element={<Product />} />
        </Route>
        <Route path="/user" element={<User />}>
          <Route path="users" element={<UsersList />} />
          <Route path="banner" element={<Banner />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="faq" element={<Faq />} />
          <Route path="transaction-type" element={<TransactionType />} />
          <Route path="transactions" element={<TransactionLists />} />
          <Route path="offers" element={<CustomOffers />} />
        </Route>
        <Route path="user-profile" element={<UserDetailLayout />}>
          <Route path=":userID" element={<UserDetailView />} />
        </Route>
        <Route path="product-detail" element={<ProductDetailLayout />}>
          <Route path=":productID" element={<ProductDetailView />} />
        </Route>
        <Route path="/staff" element={<Staff />}>
          <Route path="list" element={<StaffList />} />
        </Route>
        <Route path="/cms" element={<CMS />}>
          <Route path="policies-terms" element={<ContentList />} />
          <Route path="home-page" element={<HomePage />} />
          <Route path="video" element={<VideoContent />} />
        </Route>

        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
