import React from "react";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Style from "../positionDrag.module.scss";
import useComponentForm from "./useComponentForm";
// import { capitalizeOnSpace } from "../../../../../utils/Functions/table";

const ComponentForm = ({ formik, getFieldError, basicData, characters }) => {
  const { selectedOptions, editHomePageData, componentIndex, handleSelect } =
    useComponentForm(basicData, formik);

  return (
    <div className={`${Style.input_wrapper}`}>
      <div className={`input-wrap pro-mb-4`}>
        <Input
          label={" Title"}
          type="text"
          id="title"
          name="title"
          className={`pro-input lg ${getFieldError("title") && " error"}`}
          {...formik.getFieldProps("title")}
          error={getFieldError("title")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          errorMessage={getFieldError("title")}
        />
      </div>
      <div className={`input-wrap pro-mb-4`}>
        <div className="pro-rtl">
          <Input
            label={`(عنوان) Title`}
            type="text"
            id="alias_title"
            name="alias_title"
            className={`pro-input lg ${
              getFieldError("alias_title") && " error"
            }`}
            {...formik.getFieldProps("alias_title")}
            error={getFieldError("alias_title")}
            errorMessage={getFieldError("alias_title")}
          />
        </div>
      </div>
      {editHomePageData?.type === "products" ||
      editHomePageData?.type === "single_banner" ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select
          </label>
          <Select
            id="selected_items"
            name="selected_items"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("selected_items") && " error"
            }`}
            classNamePrefix="pro-input"
            options={selectedOptions ?? []}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={selectedOptions?.filter(
              (m) => m?.value == characters[componentIndex]?.selected_items
            )}
            onBlur={formik.handleBlur("selected_items")}
            onChange={handleSelect}
            menuPlacement="auto"
          />
          {getFieldError("selected_items") && (
            <span className="error-text">
              {getFieldError("selected_items")}
            </span>
          )}
        </div>
      ) : (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select
          </label>
          <Select
            id="selected_items"
            name="selected_items"
            isClearable={false}
            isMulti={true}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik?.touched?.selected_items &&
              formik?.errors?.selected_items &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={selectedOptions}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={
              typeof formik?.values?.selected_items === "object" &&
              (formik?.values?.selected_items || [])?.map((selectedValue) => {
                return selectedOptions?.find(
                  (option) => option?.value === selectedValue
                );
              })
            }
            onBlur={formik.handleBlur(`selected_items`)}
            onChange={(value) =>
              formik.setFieldValue(
                `selected_items`,
                value.map((v) => v?.value)
              )
            }
            menuPlacement="auto"
          />
          {formik?.touched?.selected_items &&
            formik?.errors?.selected_items && (
              <span className="error-text">
                {formik?.errors?.selected_items}
              </span>
            )}{" "}
        </div>
      )}
      <div className={`col-12 pro-d-flex pro-justify-end pro-mt-5`}>
        {/* <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleClose()}
        >
          Cancel
        </Button> */}
        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update Component"}
        </Button>
      </div>
    </div>
  );
};

export default ComponentForm;
