import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ProductVariants from "./ProductVariants";
import Style from "./productDetails.module.scss";
import ProductAddOns from "./ProductAddOns";
import useProductDetails from "./useProductDetails";
import FileUpload from "../../../../Global/FileUpload";
import Select from "react-select";
import { useRef } from "react";

const ProductDetails = ({
  getFieldError,
  formik,
  handleCloseModal,
  setUpdateAllStore,
  innerRef,
}) => {
  const {
    handleUpdateAllStore,
    handleImagePreviewChange,
    handleImagePreviewRemove,
    handleImageCustomizedPreviewRemove,
    handleImageCustomizedPreviewChange,
    Assets,
    categoryObj,
    imagePreview,
    updateAllStore,
    productBasicData,
    isUpdatingProduct,
    imageCustomizedPreview,
  } = useProductDetails({ formik, setUpdateAllStore });
  const productRef = useRef();

  return (
    <>
      <div className="row gx-4" ref={productRef}>
        <div className="col">
          <div className={`pro-p-5 pro-bg-light ${Style.box}`}>
            <div className="row gx-3">
              <div className="col-6">
                <div className="input-wrap">
                  <Input
                    label={" Name"}
                    type="text"
                    id="name"
                    name="name"
                    className={`pro-input lg ${
                      getFieldError("name") && " error"
                    }`}
                    {...formik.getFieldProps("name")}
                    error={getFieldError("name")}
                    errorMessage={getFieldError("name")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input-wrap pro-rtl">
                  <Input
                    label={"(اسم)Name"}
                    type="text"
                    id="alias_name"
                    name="alias_name"
                    className={`pro-input lg ${
                      getFieldError("alias_name") && " error"
                    }`}
                    {...formik.getFieldProps("alias_name")}
                    error={getFieldError("alias_name")}
                    errorMessage={getFieldError("alias_name")}
                  />
                </div>
              </div>
            </div>
            <div className="row gx-3">
              <div className="col-6">
                <div className="input-wrap">
                  <Input
                    label={" Product Code"}
                    type="text"
                    id="product_code"
                    name="product_code"
                    className={`pro-input lg ${
                      getFieldError("product_code") && " error"
                    }`}
                    {...formik.getFieldProps("product_code")}
                    error={getFieldError("product_code")}
                    errorMessage={getFieldError("product_code")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input-wrap">
                  <Input
                    label={"Price"}
                    type="text"
                    id="price"
                    name="price"
                    className={`pro-input lg ${
                      getFieldError("price") && " error"
                    }`}
                    {...formik.getFieldProps("price")}
                    error={getFieldError("price")}
                    errorMessage={getFieldError("price")}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 pro-mb-4">
              <div className="input-wrap">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  rows={3}
                  name="description"
                  className={`pro-input lg ${
                    formik.errors.description &&
                    formik.touched.description &&
                    " error"
                  }`}
                  value={formik?.values?.description}
                  onBlur={formik?.handleBlur("description")}
                  onChange={(e) =>
                    formik.setFieldValue("description", e.target.value)
                  }
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <span className="error-text">
                    {formik.errors.description}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 pro-mb-4">
              <div className="pro-rtl">
                <div className="input-wrap">
                  <label
                    htmlFor=""
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    ( وصف ) Description
                  </label>
                  <textarea
                    id="alias_description"
                    rows={3}
                    name="alias_description"
                    className={`pro-input lg ${
                      formik.errors.alias_description &&
                      formik.touched.alias_description &&
                      " error"
                    }`}
                    value={formik?.values?.alias_description}
                    onBlur={formik?.handleBlur("alias_description")}
                    onChange={(e) =>
                      formik.setFieldValue("alias_description", e.target.value)
                    }
                  ></textarea>
                  {formik.touched.alias_description &&
                    formik.errors.alias_description && (
                      <span className="error-text">
                        {formik.errors.alias_description}
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="col-12 pro-mb-4">
              <div className="input-wrap pro-mb-4 col">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Category
                </label>
                <Select
                  id="category_id"
                  name="category_id"
                  ref={innerRef}
                  placeholder={"Select"}
                  className={`pro-input lg  ${
                    getFieldError("category_id") && " error"
                  }`}
                  classNamePrefix="pro-input"
                  options={productBasicData?.data?.category}
                  value={categoryObj}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?._id}
                  onBlur={formik.handleBlur("category_id")}
                  onChange={(value) =>
                    formik?.setFieldValue("category_id", value?._id || null)
                  }
                  isMulti={false}
                  isClearable
                  menuPlacement="auto"
                />
                {getFieldError("category_id") && (
                  <span className="error-text">
                    {getFieldError("category_id")}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="input-wrap pro-mb-4">
                  <FileUpload
                    label={`Image(Caribou)`}
                    formik={formik}
                    isDeletable={false}
                    errorStyle={formik.errors.image && formik.touched.image}
                    logoPreview={
                      imagePreview == "" || imagePreview == null
                        ? Assets.HEADERLOGO
                        : imagePreview
                    }
                    handleLogoChange={handleImagePreviewChange}
                    handleLogoRemove={handleImagePreviewRemove}
                    getFieldError={getFieldError}
                  />
                  {formik.touched.image && formik.errors.image && (
                    <span className="error-text">{formik.errors.image}</span>
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="input-wrap pro-mb-4">
                  <FileUpload
                    label={`Image (Customized)`}
                    errorStyle={
                      formik.errors.product_image_url &&
                      formik.touched.product_image_url
                    }
                    isDeletable={true}
                    formik={formik}
                    logoPreview={imageCustomizedPreview}
                    handleLogoChange={handleImageCustomizedPreviewChange}
                    handleLogoRemove={handleImageCustomizedPreviewRemove}
                    getFieldError={getFieldError}
                  />
                  {formik.touched.product_image_url &&
                    formik.errors.product_image_url && (
                      <span className="error-text">
                        {formik.errors.product_image_url}
                      </span>
                    )}
                </div>
              </div>
            </div>
            {/* product variant mapping section */}
            {formik?.values?.variants?.map((itemVariants, itemIndex) => {
              return formik?.values?.variants?.length > 0 ? (
                <ProductVariants
                  data={itemVariants}
                  index={itemIndex}
                  formik={formik}
                  productBasicData={productBasicData}
                  getFieldError={getFieldError}
                />
              ) : (
                ""
              );
            })}

            <div className="row">
              <div className="col-6">
                <div className="pro-check-box pro-py-2">
                  <div className="form-check form-switch ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={Boolean(formik.values?.isNonVeg === 1) ?? false}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `isNonVeg`,
                          e?.target?.checked ? 1 : 0
                        )
                      }
                    />
                    <label htmlFor="default" className="pro-check-label">
                      Non Veg
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="pro-check-box pro-py-2">
                  <div className="form-check form-switch ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={Boolean(formik.values?.status === 1) ?? false}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `status`,
                          e?.target?.checked ? 1 : 2
                        )
                      }
                    />
                    <label htmlFor="default" className="pro-check-label">
                      Status
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="pro-check-box pro-py-2">
                  <div className="form-check form-switch ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={
                        Boolean(formik.values?.best_seller === 1) ?? false
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          `best_seller`,
                          e?.target?.checked ? 1 : 2
                        )
                      }
                    />
                    <label htmlFor="default" className="pro-check-label">
                      Best Seller
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="pro-check-box pro-py-2">
                  <div className="form-check form-switch ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={
                        Boolean(formik.values?.new_product === 1) ?? false
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          `new_product`,
                          e?.target?.checked ? 1 : 2
                        )
                      }
                    />
                    <label htmlFor="default" className="pro-check-label">
                      New Product
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* product add on mapping section */}
        {formik?.values?.add_on?.length > 0 && (
          <div className="col">
            <div className={`pro-bg-light pro-p-5 ${Style.box}`}>
              {formik?.values?.add_on?.map((item, itemIndex) => {
                return formik?.values?.add_on?.length > 0 ? (
                  <ProductAddOns
                    addOnKey={`add_on`}
                    data={item}
                    index={itemIndex}
                    formik={formik}
                    getFieldError={getFieldError}
                  />
                ) : (
                  ""
                );
              })}
            </div>
          </div>
        )}
        {/* product optional add on section */}
        {formik?.values?.optional_add_on?.length > 0 && (
          <div className="col">
            <div className={`pro-bg-light pro-p-5 ${Style.box}`}>
              {formik?.values?.optional_add_on?.map((item, itemIndex) => {
                return formik?.values?.optional_add_on?.length > 0 ? (
                  <ProductAddOns
                    addOnKey={`optional_add_on`}
                    data={item}
                    index={itemIndex}
                    formik={formik}
                    getFieldError={getFieldError}
                  />
                ) : (
                  ""
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className={`col-12 pro-px-5 pro-py-4 pro-bg-light pro-border-top`}>
        <div className="pro-px-5 pro-w-100 pro-d-flex pro-justify-end pro-items-center">
          <div className="pro-d-flex pro-items-center pro-pe-6 pro-me-6">
            <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
              <input
                type="checkbox"
                className="pro-check"
                id="update_all_store"
                name="update_all_store"
                checked={updateAllStore}
                onChange={handleUpdateAllStore}
              />
            </div>
            <label
              htmlFor="update_all_store"
              className="pro-font-sm pro-mb-0 pro-fw-medium"
            >
              Update All Store
            </label>
          </div>
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
            disabled={isUpdatingProduct}
          >
            {`${isUpdatingProduct ? "Updating..." : "Update"}`}
          </Button>
        </div>
      </div>
      {/* <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-mt-5 pro-px-5`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div> */}
    </>
  );
};

export default ProductDetails;
