import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
// import { capitalizeOnSpace } from "../../utils/Functions/table";
// import Select from "react-select";
import ProductVariants from "./ProductVariants";
import Assets from "../../assets/Assets";
import Style from "./productDetails.module.scss";
import ProductAddOns from "./ProductAddOns";
import useProductDetails from "./useProductDetails";
import { ProductChangeInput } from "../Global/Input";

const ProductDetails = ({
  data,
  prevData,
  headName,
  addOnKey,
  variantsKey,
  finalData,
  optionalAddOnKey,
  missMatchedKeyData,
}) => {
  const {
    checked,
    addOnChecked,
    variantChecked,
    optionalAddOnChecked,
    handleVariantsAndAddons,
    handleAddOnChecked,
    handleNameChange,
    handleNewData,
  } = useProductDetails({
    finalData,
    prevData,
    data,
  });

  return (
    <>
      {headName && (
        <div className="pro-py-3 pro-bg-light pro-px-5 pro-mb-3">
          <h5 className="pro-ttl h5 pro-fw-bolder pro-mb-0">{headName}</h5>
        </div>
      )}

      <div className="row gx-4">
        <div className="col">
          <div className={`pro-p-5 pro-bg-light ${Style.box}`}>
            <div className="row gx-3">
              <div className="col-6">
                <ProductChangeInput
                  label={"Name"}
                  fieldName="name"
                  data={data}
                  value={data?.name ?? ""}
                  missMatchedKeyData={missMatchedKeyData}
                  className={`pro-input lg ${
                    missMatchedKeyData?.includes("name")
                      ? Style.product_active
                      : ""
                  }`}
                  handleCheckboxChange={(e) =>
                    handleNameChange(e, "name", data?.name, prevData?.name)
                  }
                  // onChange={(event) => capitalizeOnSpace(event, data?.name)}
                  checked={Boolean(checked?.name === 1) ?? false}
                />
              </div>
              <div className="col-6">
                <div className="pro-rtl">
                  <ProductChangeInput
                    label={"( اسم ) Name"}
                    fieldName="alias_name"
                    data={data}
                    value={data?.alias_name ?? ""}
                    missMatchedKeyData={missMatchedKeyData}
                    className={`pro-input lg ${
                      missMatchedKeyData?.includes("alias_name")
                        ? Style.product_active
                        : ""
                    }`}
                    // onChange={(event) => capitalizeOnSpace(event, data?.name)}
                    handleCheckboxChange={(e) =>
                      handleNameChange(
                        e,
                        "alias_name",
                        data?.alias_name,
                        prevData?.alias_name
                      )
                    }
                    checked={Boolean(checked?.alias_name === 1) ?? false}
                  />
                </div>
              </div>
            </div>
            <div className="row gx-3">
              <div className="col-6">
                <ProductChangeInput
                  label={"Product Code"}
                  fieldName="product_code"
                  data={data}
                  value={data?.product_code ?? ""}
                  missMatchedKeyData={missMatchedKeyData}
                  className={`pro-input lg ${
                    missMatchedKeyData?.includes("product_code")
                      ? Style.product_active
                      : ""
                  }`}
                  // onChange={(event) =>
                  //   capitalizeOnSpace(event, data?.product_code)
                  // }
                  handleCheckboxChange={(e) =>
                    handleNameChange(
                      e,
                      "product_code",
                      data?.product_code,
                      prevData?.product_code
                    )
                  }
                  checked={Boolean(checked?.product_code === 1) ?? false}
                />
              </div>
              <div className="col-6">
                <ProductChangeInput
                  label={"Price"}
                  fieldName="price"
                  data={data}
                  value={data?.price ?? ""}
                  missMatchedKeyData={missMatchedKeyData}
                  className={`pro-input lg ${
                    missMatchedKeyData?.includes("price")
                      ? Style.product_active
                      : ""
                  }`}
                  // onChange={(event) => capitalizeOnSpace(event, data?.price)}
                  handleCheckboxChange={(e) =>
                    handleNameChange(e, "price", data?.price, prevData?.price)
                  }
                  checked={Boolean(checked?.price === 1) ?? false}
                />
              </div>
            </div>
            <div className="col-12 pro-mb-4">
              <div className="input-wrap">
                <div className="pro-d-flex pro-items-center">
                  {missMatchedKeyData?.includes("description") ? (
                    <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                      <input
                        type="checkbox"
                        className="pro-check"
                        id="status"
                        name="status"
                        checked={Boolean(checked?.description === 1) ?? false}
                        onChange={(e) =>
                          handleNameChange(
                            e,
                            "description",
                            data?.description,
                            prevData?.description
                          )
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <label
                    htmlFor=""
                    className={`pro-font-sm ${
                      missMatchedKeyData?.includes("description")
                        ? "pro-mb-0"
                        : " pro-mb-1"
                    } pro-fw-medium`}
                  >
                    Description
                  </label>
                </div>
                <textarea
                  id="description"
                  rows={3}
                  name="description"
                  readOnly
                  className={`pro-input lg ${
                    missMatchedKeyData?.includes("description")
                      ? Style.product_active
                      : ""
                  }`}
                  defaultValue={data?.description ?? {}}
                ></textarea>
              </div>
            </div>
            <div className="col-12 pro-mb-4">
              <div className="pro-rtl">
                <div className="input-wrap">
                  <div className="pro-d-flex pro-items-center">
                    {missMatchedKeyData?.includes("alias_description") ? (
                      <div className="pro-check-box pro-w-auto pro-ms-2 pro-py-2">
                        <input
                          type="checkbox"
                          className="pro-check"
                          id="status"
                          name="status"
                          checked={
                            Boolean(checked?.alias_description === 1) ?? false
                          }
                          onChange={(e) =>
                            handleNameChange(
                              e,
                              "alias_description",
                              data?.alias_description,
                              prevData?.alias_description
                            )
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <label
                      htmlFor=""
                      className="pro-font-sm pro-mb-1 pro-fw-medium"
                    >
                      ( وصف ) Description
                    </label>
                  </div>
                  <textarea
                    id="alias_description"
                    rows={3}
                    name="alias_description"
                    readOnly
                    className={`pro-input lg ${
                      missMatchedKeyData?.includes("alias_description")
                        ? Style.product_active
                        : ""
                    }`}
                    defaultValue={data?.alias_description ?? {}}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-12 pro-mb-4">
              <div className="row gx-3">
                <ProductChangeInput
                  label={"Category"}
                  fieldName="category_name"
                  data={data}
                  value={data?.category_name ?? ""}
                  missMatchedKeyData={missMatchedKeyData}
                  className={`pro-input lg ${
                    missMatchedKeyData?.includes("category_name")
                      ? Style.product_active
                      : ""
                  }`}
                  // onChange={(event) =>
                  //   capitalizeOnSpace(event, data?.category_name)
                  // }
                  handleCheckboxChange={(e) =>
                    handleNameChange(
                      e,
                      "category_name",
                      data?.category_name,
                      prevData?.category_name
                    )
                  }
                  checked={Boolean(checked?.category_name === 1) ?? false}
                />
                <div className="row gx-3">
                  {data?.image == "" || data?.image == null ? null : (
                    <div className="col">
                      <div className="input-wrap pro-mb-4">
                        <div className="pro-d-flex pro-items-center">
                          {missMatchedKeyData?.includes("image") ? (
                            <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                              <input
                                type="checkbox"
                                className="pro-check"
                                id="image"
                                name="image"
                                checked={Boolean(checked?.image === 1) ?? false}
                                onChange={(e) =>
                                  handleNameChange(
                                    e,
                                    "image",
                                    data?.image,
                                    prevData?.image
                                  )
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <label
                            htmlFor=""
                            className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                          >
                            Image (Caribou)
                          </label>
                        </div>
                        <div
                          className={`${Style.img_box} ${
                            missMatchedKeyData?.includes("image")
                              ? Style.product_active
                              : ""
                          }`}
                        >
                          <div className={`${Style.box_inner}`}>
                            <Image
                              width={100}
                              height={100}
                              src={
                                data?.image === "" || data?.image === null
                                  ? Assets?.GENDER_IMAGE
                                  : data?.image
                              }
                              alt={`image - 01`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.product_image == "" ||
                  data?.product_image == null ? null : (
                    <div className="col">
                      <div className="input-wrap pro-mb-4">
                        <div className="pro-d-flex pro-items-center">
                          {missMatchedKeyData?.includes("product_image") ? (
                            <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                              <input
                                type="checkbox"
                                className="pro-check"
                                id="product_image"
                                name="product_image"
                                checked={
                                  Boolean(checked?.product_image === 1) ?? false
                                }
                                onChange={(e) =>
                                  handleNameChange(
                                    e,
                                    "product_image",
                                    data?.product_image,
                                    prevData?.product_image
                                  )
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <label
                            htmlFor=""
                            className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                          >
                            Image (Customized)
                          </label>
                        </div>
                        <div
                          className={`${Style.img_box} ${
                            missMatchedKeyData?.includes("product_image")
                              ? Style.product_active
                              : ""
                          }`}
                        >
                          <div className={`${Style.box_inner}`}>
                            <Image
                              width={100}
                              height={100}
                              src={
                                data?.product_image === "" ||
                                data?.product_image === null
                                  ? Assets?.GENDER_IMAGE
                                  : data?.product_image
                              }
                              alt={`image - 01`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {data?.variants?.map((itemVariants, itemIndex) => {
                  return data?.variants?.length > 0 ? (
                    <ProductVariants
                      data={itemVariants}
                      index={itemIndex}
                      variantsKey={variantsKey}
                      missMatchedKeyData={missMatchedKeyData}
                      handleVariantsAndAddons={handleVariantsAndAddons}
                      checked={checked}
                      handleNewData={handleNewData}
                      variantChecked={variantChecked}
                    />
                  ) : (
                    ""
                  );
                })}

                <div className="row">
                  <div className="col-6">
                    <div className="pro-check-box pro-py-2">
                      {missMatchedKeyData?.includes("isNonVeg") ? (
                        <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                          <input
                            type="checkbox"
                            className="pro-check"
                            id="isNonVeg"
                            name="isNonVeg"
                            checked={Boolean(checked?.isNonVeg === 1) ?? false}
                            onChange={(e) =>
                              handleNameChange(
                                e,
                                "isNonVeg",
                                data?.isNonVeg,
                                prevData?.isNonVeg
                              )
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`${
                          missMatchedKeyData?.includes("isNonVeg")
                            ? Style.product_active
                            : ""
                        }`}
                      >
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="isNonVeg"
                            disabled
                            checked={Boolean(data?.isNonVeg === 1) ?? false}
                          />
                          <label className="form-check-label" for="isNonVeg">
                            Non Veg
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="pro-check-box pro-py-2">
                      {missMatchedKeyData?.includes("status") ? (
                        <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                          <input
                            type="checkbox"
                            className="pro-check"
                            id="status"
                            name="status"
                            checked={Boolean(checked?.status === 1) ?? false}
                            onChange={(e) =>
                              handleNameChange(
                                e,
                                "status",
                                data?.status,
                                prevData?.status
                              )
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`${
                          missMatchedKeyData?.includes("status")
                            ? Style.product_active
                            : ""
                        }`}
                      >
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="status"
                            disabled
                            checked={Boolean(data?.status === 1) ?? false}
                          />
                          <label className="form-check-label" for="status">
                            Status
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="pro-check-box pro-py-2">
                      {missMatchedKeyData?.includes("best_seller") ? (
                        <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                          <input
                            type="checkbox"
                            className="pro-check"
                            id="best_seller"
                            name="best_seller"
                            checked={
                              Boolean(checked?.best_seller === 1) ?? false
                            }
                            onChange={(e) =>
                              handleNameChange(
                                e,
                                "best_seller",
                                data?.best_seller,
                                prevData?.best_seller
                              )
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`${
                          missMatchedKeyData?.includes("best_seller")
                            ? Style.product_active
                            : ""
                        }`}
                      >
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            disabled
                            id="best_seller"
                            checked={Boolean(data?.best_seller === 1) ?? false}
                          />
                          <label className="form-check-label" for="best_seller">
                            Best Seller
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="pro-check-box pro-py-2">
                      {missMatchedKeyData?.includes("new_product") ? (
                        <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                          <input
                            type="checkbox"
                            className="pro-check"
                            id="new_product"
                            name="new_product"
                            checked={
                              Boolean(checked?.new_product === 1) ?? false
                            }
                            onChange={(e) =>
                              handleNameChange(
                                e,
                                "new_product",
                                data?.new_product,
                                prevData?.new_product
                              )
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`${
                          missMatchedKeyData?.includes("new_product")
                            ? Style.product_active
                            : ""
                        }`}
                      >
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            disabled
                            id="new_product"
                            checked={Boolean(data?.new_product === 1) ?? false}
                          />
                          <label className="form-check-label" for="new_product">
                            New Product
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {data?.add_on?.length > 0 && (
          <div className="col">
            <div className={`pro-bg-light pro-p-5 ${Style.box}`}>
              {data?.add_on?.map((item, itemIndex) => {
                return data?.add_on?.length > 0 ? (
                  <ProductAddOns
                    data={item}
                    variantData={data?.variants}
                    index={itemIndex}
                    addOnKey={addOnKey}
                    addOnKeyName={`add_on`}
                    missMatchedKeyData={missMatchedKeyData}
                    handleNewData={handleNewData}
                    checked={checked}
                    addOnChecked={addOnChecked}
                    handleVariantsAndAddons={handleVariantsAndAddons}
                    handleAddOnChecked={handleAddOnChecked}
                  />
                ) : (
                  ""
                );
              })}
            </div>
          </div>
        )}
        {data?.optional_add_on?.length > 0 && (
          <div className="col">
            <div className={`pro-bg-light pro-p-5 ${Style.box}`}>
              {data?.optional_add_on?.map((item, itemIndex) => {
                return data?.optional_add_on?.length > 0 ? (
                  <ProductAddOns
                    data={item}
                    variantData={data?.variants}
                    index={itemIndex}
                    addOnKey={optionalAddOnKey}
                    addOnKeyName={`optional_add_on`}
                    missMatchedKeyData={missMatchedKeyData}
                    handleNewData={handleNewData}
                    checked={checked}
                    addOnChecked={optionalAddOnChecked}
                    handleVariantsAndAddons={handleVariantsAndAddons}
                    handleAddOnChecked={handleAddOnChecked}
                  />
                ) : (
                  ""
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDetails;
