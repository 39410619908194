import React from "react";
import useSubAdmin from "./useSubAdmin";
import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
// import {
//   capitalizeInitial,
//   capitalizeOnSpace,
// } from "../../../../utils/Functions/table";

const AddSubAdmin = ({ isStickyFooter, handleCreateClick, refetch }) => {
  const {
    formik,
    isEdit,
    roleObj,
    storeObj,
    isLoading,
    countryObj,
    countryCodeOptions,
    imagePreview,
    imageName,
    showNewPassword,
    basicData,
    storeList,
    isStoreAvailable,
    closeModal,
    getFieldError,
    handleImageChange,
    handleAllocateStore,
    setIsPasswordChange,
    handleShowNewPassword,
  } = useSubAdmin(handleCreateClick, refetch);

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"First Name"}
          type="text"
          id="first_name"
          name="first_name"
          className={`pro-input lg ${getFieldError("first_name") && " error"}`}
          {...formik.getFieldProps("first_name")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("first_name")}
          errorMessage={getFieldError("first_name")}
        />
        <Input
          label={"Last Name"}
          type="text"
          id="last_name"
          name="last_name"
          className={`pro-input lg ${getFieldError("last_name") && " error"}`}
          {...formik.getFieldProps("last_name")}
          // onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
          error={getFieldError("last_name")}
          errorMessage={getFieldError("last_name")}
        />
        <div className="input-wrap pro-mb-4 ">
          <div className="input-country-wrap ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mobile Number
            </label>
            <div className="input-main-wrap verify-btn-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="dialcode"
                  options={countryCodeOptions}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  value={countryObj}
                  classNamePrefix="pro-input"
                  className="country-select"
                  onBlur={formik.handleBlur("dialcode")}
                  menuPlacement="auto"
                  onChange={(value) =>
                    formik.setFieldValue("dialcode", value?.value)
                  }
                ></Select>
              </div>
              <input
                type="text"
                id="personal_number"
                name="personal_number"
                className={`pro-input lg ${
                  formik.errors.personal_number &&
                  formik.touched.personal_number &&
                  " error"
                }`}
                {...formik.getFieldProps("personal_number")}
              />
            </div>
            {formik.errors.personal_number &&
              formik.touched.personal_number && (
                <>
                  <span className="error-text">
                    {formik.errors.personal_number}
                  </span>
                </>
              )}
          </div>
        </div>
        <Input
          label={"Contact Number"}
          type="contact_number"
          id="contact_number"
          name="contact_number"
          className={`pro-input lg ${
            getFieldError("contact_number") && " error"
          }`}
          {...formik.getFieldProps("contact_number")}
          error={getFieldError("contact_number")}
          errorMessage={getFieldError("contact_number")}
        />

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Role
          </label>
          <Select
            id="role_id"
            name="role_id"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("role_id") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={roleObj}
            onBlur={formik.handleBlur("role_id")}
            onChange={(value) =>
              formik?.setFieldValue("role_id", value?._id || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("role_id") && (
            <span className="error-text">{getFieldError("role_id")}</span>
          )}
        </div>
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="is_customer_key"
            name="pro-checkbox"
            checked={Boolean(formik.values?.is_customer_key === 1) ?? false}
            onChange={(e) => handleAllocateStore(e)}
            disabled={isStoreAvailable}
          />
          <label htmlFor="default" className="pro-check-label">
            Allocate Store
          </label>
        </div>
        {formik.values.is_customer_key === 1 && (
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Store
            </label>
            <Select
              id="customer_key"
              name="customer_key"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("customer_key") && " error"
              }`}
              classNamePrefix="pro-input"
              options={storeList ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.customer_key}
              value={storeObj}
              onBlur={formik.handleBlur("customer_key")}
              onChange={(value) =>
                formik?.setFieldValue(
                  "customer_key",
                  value?.customer_key || null
                )
              }
              menuPlacement="auto"
              isDisabled={isStoreAvailable}
            />
            {getFieldError("customer_key") && (
              <span className="error-text">
                {getFieldError("customer_key")}
              </span>
            )}
          </div>
        )}
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.image && formik.touched.image && " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("image")}
              onChange={(e) => handleImageChange(e)}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  imageName !== ""
                    ? imageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.image && formik.errors.image && (
              <span className="error-text">{formik.errors.image}</span>
            )}
          </div>
          {imagePreview && (
            <div className={`col-2 pro-my-2`}>
              <div className={``}>
                <Image
                  width={100}
                  height={100}
                  src={imagePreview}
                  alt={`image - 01`}
                />
              </div>
            </div>
          )}
        </div>
        {isEdit ? (
          <div className="pro-check-box pro-py-2">
            <input
              type="checkbox"
              className="pro-check"
              id="password_change"
              name="pro-checkbox"
              checked={Boolean(formik.values?.password_change === 1) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  `password_change`,
                  e?.target?.checked ? 1 : 2
                );
                setIsPasswordChange(e?.target?.checked);
              }}
            />
            <label htmlFor="default" className="pro-check-label">
              Password Change
            </label>
          </div>
        ) : (
          ""
        )}
        <Input
          label={"Email"}
          type="email"
          id="email"
          name="email"
          className={`pro-input lg ${getFieldError("email") && " error"}`}
          {...formik.getFieldProps("email")}
          error={getFieldError("email")}
          errorMessage={getFieldError("email")}
        />
        {!isEdit || formik?.values?.password_change === 1 ? (
          <div className="input-wrap pro-mb-5">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Password
            </label>
            <input
              name="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              className={`pro-input lg ${
                formik.errors.password &&
                (formik.touched.password || formik.values.password) &&
                "error"
              }`}
              autoComplete="new-password"
            />
            {showNewPassword ? (
              <button
                className={`hide-icon`}
                onClick={(e) => handleShowNewPassword(e)}
                type="button"
              >
                <span className="material-symbols-outlined">
                  {" "}
                  visibility_off{" "}
                </span>
              </button>
            ) : (
              <button
                className={`hide-icon`}
                onClick={(e) => handleShowNewPassword(e)}
                type="button"
              >
                <span className="material-symbols-outlined"> visibility </span>
              </button>
            )}
            {formik.errors.password &&
              (formik.touched.password || formik.values.password) && (
                <span className="error-text">{formik?.errors?.password}</span>
              )}
          </div>
        ) : (
          ""
        )}
        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {!isEdit && !isLoading
            ? "Register"
            : isLoading && !isEdit
            ? "Registering..."
            : isLoading && isEdit
            ? "Updating..."
            : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default AddSubAdmin;
