import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  showProductEditModal: false,
  selectedId: "",
  storeData: [],
  editStoreData: {},
  activeId: 1,
  statusData: {},
  activeTab: "Basic",
  imagePreview: "",
  imageName: "",
  curbTimeError: {},
  pickupTimeError: {},
};
export const createStore = createAsyncThunk(
  "/store/store",
  async (data, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.post("/store/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStoreEditData = createAsyncThunk(
  "/store/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/store/edit?store_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreEditData.pending, (state) => {
        state.isLoading = true;
        // state.editAdminData = {};
        state.error = null;
      })
      .addCase(getStoreEditData.fulfilled, (state, action) => {
        state.editStoreData = action.payload?.data;
      })
      .addCase(getStoreEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = storeSlice.actions;
export default storeSlice.reducer;
