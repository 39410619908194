import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Admin/Products/productSlice";
import { useSelector } from "react-redux";
import {
  useGetProductImageMutation,
  useGetProductBasicDataQuery,
} from "../../../../../store/queries/Product";
import Assets from "../../../../../assets/Assets";

const useProductDetails = ({ formik, setUpdateAllStore }) => {
  const dispatch = useDispatch();
  const [selectProductImage] = useGetProductImageMutation();
  const { data: productBasicData } = useGetProductBasicDataQuery();
  const { imagePreview, imageCustomizedPreview, isUpdatingProduct } =
    useSelector((state) => state.product);

  const handleImagePreviewChange = (event) => {
    //TODO need to add validation
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      selectProductImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(`image`, response?.data?.data?.image_path);
        }
      });

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImagePreviewRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("image", "");
    formik?.setFieldError("image", "Upload image");
    formik?.setFieldTouched("image", true);
  };
  const handleImageCustomizedPreviewChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      selectProductImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            `product_image_url`,
            response?.data?.data?.image_path
          );
        }
      });

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imageCustomizedPreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageCustomizedPreviewRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imageCustomizedPreview = "";
      })
    );
    formik.setFieldValue("product_image_url", "");
    formik?.setFieldError("product_image_url", "Upload image");
    formik?.setFieldTouched("product_image_url", true);
  };

  let categoryObj = productBasicData?.data?.category?.filter(
    (item) => item?._id === formik?.values?.category_id
  );
  const handleUpdateAllStore = (e) => {
    setUpdateAllStore(e?.target?.checked);
  };

  return {
    Assets,
    categoryObj,
    imagePreview,
    productBasicData,
    isUpdatingProduct,
    imageCustomizedPreview,
    handleUpdateAllStore,
    handleImagePreviewChange,
    handleImagePreviewRemove,
    handleImageCustomizedPreviewRemove,
    handleImageCustomizedPreviewChange,
  };
};

export default useProductDetails;
