import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useSendExcelData from "./useSendExcelData";

const SendExcelData = ({
  sendExcelData,
  isExcelLinkSend,
  handleCloseModal,
}) => {
  const { formik, getFieldError } = useSendExcelData(sendExcelData);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Email"}
          type="text"
          id="email"
          name="email"
          className={`pro-input lg ${getFieldError("email") && " error"}`}
          {...formik.getFieldProps("email")}
          error={getFieldError("email")}
          errorMessage={getFieldError("email")}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={isExcelLinkSend}
        >
          {`${isExcelLinkSend ? "Sending..." : "Send"}`}
        </Button>
      </div>
    </div>
  );
};

export default SendExcelData;
