import { useState } from "react";
import { updateConfig } from "../../../../store/slices/CMS/cmsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const usePositionDrag = (characters, handleUpdateHomePage) => {
  const dispatch = useDispatch();
  const [cardActive, setCardActive] = useState(false);
  const cmsState = useSelector((state) => state.cms);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(
      updateConfig((state) => {
        state.characters = items;
      })
    );
    handleUpdateHomePage(items);
  };
  const handleCardClick = (data, i) => {
    dispatch(
      updateConfig((state) => {
        state.editHomePageData = data;
        state.componentIndex = i;
      })
    );
  };

  const handleAddComponent = (data) => {
    let newData = [...characters, data];
    dispatch(
      updateConfig((state) => {
        state.characters = newData;
      })
    );
    handleUpdateHomePage(newData);
  };
  const handleDeleteComponent = () => {
    const newData = characters?.filter(
      (item, index) => index !== cmsState.selectedComponentIndex
    );
    dispatch(
      updateConfig((state) => {
        state.characters = newData;
      })
    );
    handleUpdateHomePage(newData);
    handleDeleteModal();
  };
  const handleDeleteModal = (selectedComponent, index) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedComponent = selectedComponent?.title;
        state.selectedComponentIndex = index;
      })
    );
  };
  return {
    cmsState,
    cardActive,
    handleDeleteComponent,
    handleAddComponent,
    handleDeleteModal,
    handleOnDragEnd,
    handleCardClick,
    setCardActive,
  };
};

export default usePositionDrag;
