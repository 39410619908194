import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useProductVariants from "./useProductAddOns";
// import { capitalizeOnSpace } from "../../../utils/Functions/table";
import Assets from "../../../assets/Assets";
import Style from "../productDetails.module.scss";
import Select from "react-select";

const ProductAddOns = ({
  data,
  index,
  addOnKey,
  addOnKeyName,
  checked,
  addOnChecked,
  variantData,
  handleNewData,
  handleAddOnChecked,
  missMatchedKeyData,
  handleVariantsAndAddons,
}) => {
  const { checkMissMatchKey, mappedVariantListOptions } = useProductVariants(
    addOnKey,
    variantData
  );

  return (
    <>
      <div
        className={` ${Style.repeat_box} ${
          addOnKey?.[index]?.is_new === true ? Style.product_active : ""
        }`}
      >
        <div className="pro-d-flex pro-items-center pro-mb-4">
          {addOnKey?.[index]?.is_new === true ? (
            <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
              <input
                type="checkbox"
                className="pro-check"
                id={`addOnChecked?.[${index}]?.addon_new_checked`}
                name={`addOnChecked?.[${index}]?.addon_new_checked`}
                checked={
                  Boolean(addOnChecked?.[index]?.addon_new_checked === 1) ??
                  false
                }
                onChange={(e) =>
                  handleNewData(e, `${addOnKeyName}`, data, index)
                }
              />
            </div>
          ) : (
            ""
          )}
          <h6 className="pro-font-sm pro-fw-bolder pro-mb-0">{`Product AddOns (${
            data?.posist_name == "" || data?.posist_name == null
              ? index + 1
              : data?.posist_name
          })`}</h6>
        </div>
        <div className="" key={index}>
          <div className="pro-mb-4 col-12">
            <div className="row gx-3">
              <div className="col-6">
                <div className="input-wrap">
                  <div className="pro-d-flex pro-items-center">
                    {checkMissMatchKey("name", index) &&
                    !addOnKey?.[index]?.is_new ? (
                      <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                        <input
                          type="checkbox"
                          className="pro-check"
                          id="name"
                          name="name"
                          checked={
                            Boolean(
                              checked?.[addOnKeyName]?.[index]?.name === 1
                            ) ?? false
                          }
                          onChange={(e) =>
                            handleVariantsAndAddons(
                              e,
                              `${addOnKeyName}.name`,
                              data?.name,
                              index
                            )
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <label
                      htmlFor=""
                      className="pro-font-sm pro-mb-1 pro-fw-medium"
                    >
                      Name
                    </label>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    readOnly
                    className={`pro-input lg ${
                      checkMissMatchKey("name", index)
                        ? Style.product_active
                        : ""
                    }`}
                    value={data?.name ?? ""}
                    // onChange={(event) => capitalizeOnSpace(event, data?.name)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="pro-rtl">
                  <div className="input-wrap">
                    <div className="pro-d-flex pro-items-center">
                      {checkMissMatchKey("alias_name", index) &&
                      !addOnKey?.[index]?.is_new ? (
                        <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                          <input
                            type="checkbox"
                            className="pro-check"
                            id="alias_name"
                            name="alias_name"
                            checked={
                              Boolean(
                                checked?.[addOnKeyName]?.[index]?.alias_name ===
                                  1
                              ) ?? false
                            }
                            onChange={(e) =>
                              handleVariantsAndAddons(
                                e,
                                `${addOnKeyName}.alias_name`,
                                data?.alias_name,
                                index
                              )
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <label
                        htmlFor=""
                        className="pro-font-sm pro-mb-1 pro-fw-medium"
                      >
                        {`(اسم)Name`}
                      </label>
                    </div>
                    <input
                      type="text"
                      id="alias_name"
                      name="alias_name"
                      readOnly
                      className={`pro-input lg ${
                        checkMissMatchKey("alias_name", index)
                          ? Style.product_active
                          : ""
                      }`}
                      defaultValue={data?.alias_name ?? ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="input-wrap pro-mb-4">
            <div className="pro-d-flex pro-items-center">
              {checkMissMatchKey("price", index) &&
              !addOnKey?.[index]?.is_new ? (
                <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                  <input
                    type="checkbox"
                    className="pro-check"
                    id="price"
                    name="price"
                    checked={
                      Boolean(checked?.[addOnKeyName]?.[index]?.price === 1) ??
                      false
                    }
                    onChange={(e) =>
                      handleVariantsAndAddons(
                        e,
                        `${addOnKeyName}.price`,
                        data?.price,
                        index
                      )
                    }
                  />
                </div>
              ) : (
                ""
              )}
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {`Price`}
              </label>
            </div>
            <input
              type="text"
              id="price"
              name="price"
              readOnly
              className={`pro-input lg ${
                checkMissMatchKey("price", index) ? Style.product_active : ""
              }`}
              value={data?.price ?? ""}
              // onChange={(event) => capitalizeOnSpace(event, data?.price)}
            />
          </div>
          <div className="input-wrap pro-mb-4">
            <div className="pro-d-flex pro-items-center">
              {checkMissMatchKey("parent_id", index) &&
              !addOnKey?.[index]?.is_new ? (
                <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                  <input
                    type="checkbox"
                    className="pro-check"
                    id="parent_id"
                    name="parent_id"
                    checked={
                      Boolean(
                        checked?.[addOnKeyName]?.[index]?.parent_id === 1
                      ) ?? false
                    }
                    onChange={(e) =>
                      handleVariantsAndAddons(
                        e,
                        `${addOnKeyName}.parent_id`,
                        data?.parent_id,
                        index
                      )
                    }
                  />
                </div>
              ) : (
                ""
              )}
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {`Mapped Variants`}
              </label>
            </div>
            <Select
              id="parent_id"
              name="parent_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg ${
                checkMissMatchKey("parent_id", index)
                  ? Style.product_active
                  : ""
              }`}
              classNamePrefix="pro-input"
              options={mappedVariantListOptions}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              value={mappedVariantListOptions?.filter((m) =>
                data?.parent_id?.includes?.(m?.value)
              )}
              isMulti={true}
              isDisabled
              menuPlacement="auto"
            />
          </div>
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <div className="pro-d-flex pro-items-center">
                {checkMissMatchKey("description", index) &&
                !addOnKey?.[index]?.is_new ? (
                  <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                    <input
                      type="checkbox"
                      className="pro-check"
                      id="description"
                      name="description"
                      checked={
                        Boolean(
                          checked?.[addOnKeyName]?.[index]?.description === 1
                        ) ?? false
                      }
                      onChange={(e) =>
                        handleVariantsAndAddons(
                          e,
                          `${addOnKeyName}.description`,
                          data?.description,
                          index
                        )
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Description
                </label>
              </div>
              <textarea
                id="description"
                rows={3}
                name="description"
                readOnly
                className={`pro-input lg ${
                  checkMissMatchKey("description", index)
                    ? Style.product_active
                    : ""
                }`}
                defaultValue={data?.description ?? ""}
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <div className="pro-rtl">
              <div className="input-wrap">
                <div className="pro-d-flex pro-items-center">
                  {checkMissMatchKey("alias_description", index) &&
                  !addOnKey?.[index]?.is_new ? (
                    <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                      <input
                        type="checkbox"
                        className="pro-check"
                        id="alias_description"
                        name="alias_description"
                        checked={
                          Boolean(
                            checked?.[addOnKeyName]?.[index]
                              ?.alias_description === 1
                          ) ?? false
                        }
                        onChange={(e) =>
                          handleVariantsAndAddons(
                            e,
                            `${addOnKeyName}.alias_description`,
                            data?.alias_description,
                            index
                          )
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <label
                    htmlFor=""
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    ( وصف ) Description
                  </label>
                </div>

                <textarea
                  id="alias_description"
                  rows={3}
                  name="alias_description"
                  readOnly
                  className={`pro-input lg ${
                    checkMissMatchKey("alias_description", index)
                      ? Style.product_active
                      : ""
                  }`}
                  defaultValue={data?.alias_description ?? ""}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            {data?.add_on_image == "" || data?.add_on_image == null ? null : (
              <div className="col-6">
                <div className="input-wrap pro-mt-4">
                  <div className="pro-d-flex pro-items-center">
                    {checkMissMatchKey("add_on_image", index) &&
                    !addOnKey?.[index]?.is_new ? (
                      <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                        <input
                          type="checkbox"
                          className="pro-check"
                          id="add_on_image"
                          name="add_on_image"
                          checked={
                            Boolean(
                              checked?.[addOnKeyName]?.[index]?.add_on_image ===
                                1
                            ) ?? false
                          }
                          onChange={(e) =>
                            handleVariantsAndAddons(
                              e,
                              `${addOnKeyName}.add_on_image`,
                              data?.add_on_image,
                              index
                            )
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <label
                      htmlFor=""
                      className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                    >
                      {`Image (Caribou)`}
                    </label>
                  </div>

                  <div
                    className={`${Style.img_box} ${
                      checkMissMatchKey("add_on_image", index)
                        ? Style.product_active
                        : ""
                    }`}
                  >
                    <div className={`${Style.box_inner}`}>
                      <Image
                        width={100}
                        height={100}
                        src={
                          data?.add_on_image === "" ||
                          data?.add_on_image === null
                            ? Assets?.GENDER_IMAGE
                            : data?.add_on_image
                        }
                        alt={`image - 01`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {data?.image == "" || data?.image == null ? null : (
              <div className="col-6">
                <div className="input-wrap pro-mt-4">
                  <div className="pro-d-flex pro-items-center">
                    {checkMissMatchKey("image", index) &&
                    !addOnKey?.[index]?.is_new ? (
                      <div className="pro-check-box pro-w-auto pro-me-2 pro-py-2">
                        <input
                          type="checkbox"
                          className="pro-check"
                          id="image"
                          name="image"
                          checked={
                            Boolean(
                              checked?.[addOnKeyName]?.[index]?.image === 1
                            ) ?? false
                          }
                          onChange={(e) =>
                            handleVariantsAndAddons(
                              e,
                              `${addOnKeyName}.image`,
                              data?.image,
                              index
                            )
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <label
                      htmlFor=""
                      className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                    >
                      {`Image (Customized)`}
                    </label>
                  </div>

                  <div
                    className={`${Style.img_box} ${
                      checkMissMatchKey("image", index)
                        ? Style.product_active
                        : ""
                    }`}
                  >
                    <div className={`${Style.box_inner}`}>
                      <Image
                        width={100}
                        height={100}
                        src={
                          data?.image === "" || data?.image === null
                            ? Assets?.GENDER_IMAGE
                            : data?.image
                        }
                        alt={`image - 01`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductAddOns;
