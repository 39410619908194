import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useCMS = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Home",
      link: "/cms/home-page",
      icon: <span className="material-symbols-outlined x4">home</span>,
      active: checkIfActiveRoute("/cms/home-page", true),
    },
    {
      title: "Video Content",
      link: "/cms/video",
      icon: <span className="material-symbols-outlined x4">video_library</span>,
      active: checkIfActiveRoute("/cms/video", true),
    },
    {
      title: "Policies and Terms",
      link: "/cms/policies-terms",
      icon: <span className="material-symbols-outlined x4">privacy_tip</span>,
      active: checkIfActiveRoute("/cms/policies-terms", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );
    menus?.push("Home");
    menus?.push("Video Content");
    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useCMS;
