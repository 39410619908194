import { getAdminAxiosInstance } from "../../../api/Admin";

export const createTier = async (params) => {
    const api = await getAdminAxiosInstance();
    try {
    const response = await api.post(`/tier/store`, params);
    return response;
    } catch (error) {
    return error?.response?.data;
    }
   };

   export const deleteTier = async (tierId) => {
    const api = await getAdminAxiosInstance();
  
    try {
      const response = await api.get(`/tier/destroy?tier_id=${tierId}`);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  };