import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Tier/tierSlice";
import { toast } from "react-toastify";
import { createTier } from "../api";
import { useEffect } from "react";

const useAddTier = (handleClose, refetch) => {
  const dispatch = useDispatch();
  const { isEdit, editTierData } = useSelector((state) => state.tier);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter First Name")
      .max(15, "The name may not be greater than 15 characters."),
    alias_name: Yup.string().trim().required("Enter Last Name"),
    point: Yup.number()
    .typeError("Please enter a valid number")
    .integer("Please enter a valid integer")
    .required("Enter Point"),
    description: Yup.string()
      .required("Enter Description")
      .min(5, " The name must be at least 5 characters"),
    alias_description: Yup.string()
      .required("Enter Alias Description")
      .min(5, " The name must be at least 5 characters"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      alias_name: "",
      point: "",
      status: 2,
      description: "",
      alias_description: "",
    },

    validationSchema: validation,
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      if (!isEdit) {
        createTier(subData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Tier Created successfully`);
            handleClose();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (
            response?.data?.status_code === 422 &&
            response?.data?.errors
          ) {
            Object.keys(response.data.errors).forEach((field) => {
              formik.setFieldError(field, response.data.errors[field]);
            });
          } else {
            toast.error(`Something went wrong !`);
            handleClose();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        });
      } else {
        let updateTierData = { tier_id: editTierData?._id, ...subData };

        createTier(updateTierData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Tier Updated successfully`);
            handleClose();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (
            response?.data?.status_code === 422 &&
            response?.data?.errors
          ) {
            Object.keys(response.data.errors).forEach((field) => {
              formik.setFieldError(field, response.data.errors[field]);
            });
          } else {
            toast.error(`Something went wrong !`);
            handleClose();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        name: editTierData?.name || "",
        alias_name: editTierData?.alias_name || "",
        point: editTierData?.point || "",
        status: editTierData?.status || 2,
        description: editTierData?.description || "",
        alias_description: editTierData?.alias_description || "",
      });
    }
  }, [isEdit, editTierData]);

  return {
    formik,
    isEdit,
    getFieldError,
  };
};

export default useAddTier;
