import React from "react";
import ProductDetails from "../../../../ProductDetails";

const ActualDetails = ({ data }) => {
  return (
      <ProductDetails headName={`Actual Product Details`} data={data} />
  );
};

export default ActualDetails;
