import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetTierListDataQuery,
  useUpdataTierHeadDataMutation,
} from "../../../store/queries/Admin";
import {
  getTierEditData,
  updateConfig,
} from "../../../store/slices/Admin/Tier/tierSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteTier } from "./api";

const useTier = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const tierState = useSelector((state) => state.tier);
  const { selectedId } = useSelector((state) => state.tier);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: tierList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetTierListDataQuery({
    page_size: tierState?.currentPageSize,
    page: tierState?.currentPage,
    sort: tierState?.sortBy,
    sort_order: tierState?.sortOrder,
    search: tierState?.search,
  });
  const [updateTierFields] = useUpdataTierHeadDataMutation();

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (tierState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = tierState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getTierEditData(e?.[0]));
  };
  const handleDelete = async () => {
    const response = await deleteTier(selectedId);
    if (response && response.status === 200) {
      toast.success(`Tier deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  useEffect(() => {
    if (tierState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [tierState.clearSelection]);

  return {
    tierList,
    tierState,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    handleCreateClick,
    handleEditColumnsClick,
    handleEditAction,
    handleDeleteModal,
    handleDelete,
    handlePagination,
    updateTierFields,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useTier;
