import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api/Admin";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}filter=${params?.filter || "all"}&${getParams("sort")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || ""
          }&${getParams("user_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const user = createApi({
  reducerPath: "userApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "User",
    "UserProfile",
    "OrderListing",
    "PointHistory",
    "Transactions",
    "TransactionType",
    "StoreListing",
    "ProductListing",
    "vehicleListing",
    "Custom Offer",
  ],
  endpoints: (builder) => ({
    getUserListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/user/index",
      }),
      providesTags: ["User"],
    }),
    updateUserTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["User"],
    }),
    getUserDetailData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/user/show`,
      }),
      providesTags: ["UserProfile"],
    }),
    getOrderListingData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/order/list`,
      }),
      providesTags: ["OrderListing"],
    }),
    updateOrderListingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["OrderListing"],
    }),
    getPointHistoryData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/user/point-history`,
      }),
      providesTags: ["PointHistory"],
    }),
    updatePointHistoryTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["PointHistory"],
    }),
    getTransactionsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/user-wallet-transaction`,
      }),
      providesTags: ["Transactions"],
    }),
    updateTransactionTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Transactions"],
    }),
    // transaction type starts here
    getTransactionsTypeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/transaction-type/index`,
      }),
      providesTags: ["TransactionType"],
    }),
    updateTransactionTypeTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),
      invalidatesTags: ["TransactionType"],
    }),
    // transaction type ends here

    // userStore list starts here
    getStoreListingData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `favourite/store-list`,
      }),
      providesTags: ["StoreListing"],
    }),
    updateStoreListTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["StoreListing"],
    }),
    // userStore list ends here

    // user Product list starts here
    getproductListingData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `favourite/product-list`,
      }),
      providesTags: ["ProductListing"],
    }),
    updateProductListTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["ProductListing"],
    }),
    // user Product list ends here
    // vehicleListing starts here
    getVehicleListingData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `vehicle-details/index`,
      }),
      providesTags: ["vehicleListing"],
    }),
    updateVehicleListTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["vehicleListing"],
    }),
    //vehicleListing ends here
    //custom offers section start here
    getCustomOffersListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/custom-offer/index`,
      }),
      providesTags: ["Custom Offer"],
    }),
    updateCustomOffersTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/user-field/store",
      }),
      invalidatesTags: ["Custom Offer"],
    }),

    //custom offers ends here
  }),
});

export const {
  useGetUserListDataQuery,
  useGetOrderListingDataQuery,
  useGetPointHistoryDataQuery,
  useGetTransactionsDataQuery,
  useGetUserDetailDataQuery,
  useUpdateUserTableHeadDataMutation,
  useGetTransactionsTypeDataQuery,
  useUpdateTransactionTypeTableHeadDataMutation,
  useGetStoreListingDataQuery,
  useGetproductListingDataQuery,
  useGetVehicleListingDataQuery,
  useUpdateOrderListingTableHeadDataMutation,
  useUpdatePointHistoryTableHeadDataMutation,
  useUpdateProductListTableHeadDataMutation,
  useUpdateStoreListTableHeadDataMutation,
  useUpdateTransactionTableHeadDataMutation,
  useUpdateVehicleListTableHeadDataMutation,
  useGetCustomOffersListDataQuery,
  useUpdateCustomOffersTableHeadDataMutation,
} = user;
