import { getAdminAxiosInstance } from "../../../api/Admin";

export const fetchMenuData = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/product/fetch-menu`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const posistMenuFetchingData = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`/product/posist-fetch-menu?store_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const ManualMenuFetching = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`/product/fetch-menu-all`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
