const useProductAddOns = (addOnKey, variantData) => {
  const checkMissMatchKey = (fieldName, index) => {
    return Object.keys(addOnKey?.[index] ?? {})?.includes(fieldName);
  };
  let mappedVariantListOptions = (variantData ?? [])?.map(
    ({ posist_name, baseItem }) => {
      return {
        label: posist_name,
        value: baseItem,
      };
    }
  );

  return {
    checkMissMatchKey,
    mappedVariantListOptions,
  };
};

export default useProductAddOns;
