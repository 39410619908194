import PositionCard from "../../../Global/PositionCard";
import Style from "./positionDrag.module.scss";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import usePositionDrag from "./usePositionDrag";
import ComponentForm from "./ComponentForm";
import {
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
const PositionDrag = ({
  formik,
  basicData,
  characters,
  getFieldError,
  handleUpdateHomePage,
}) => {
  const {
    cmsState,
    cardActive,
    handleDeleteComponent,
    handleAddComponent,
    handleDeleteModal,
    handleOnDragEnd,
    handleCardClick,
    setCardActive,
  } = usePositionDrag(characters, handleUpdateHomePage);

  return (
    <>
      <div className={`${Style.wrapper} row pro-flex-nowrap gx-4`}>
        <div className={`col-3`}>
          <div className={`${Style.box} pro-h-100`}>
            <div
              className={`pro-d-flex pro-justify-between pro-items-center ${Style.title_wrap}`}
            >
              <h6 className="pro-mb-0 pro-ttl h6">Components</h6>
              {/* <button className={`pro-btn pro-p-1 ${Style.option_btn}`}>
                  <span className="material-symbols-outlined"> more_vert </span>
                </button> */}
            </div>
            <div className={`${Style.card_wrap}`}>
              {basicData?.data?.homeComponents?.map((item, index) => (
                <PositionCard
                  key={item?._id}
                  componentData={item}
                  cardIndex={item?._id}
                  isActive={cardActive === item?._id}
                  setCardActive={setCardActive}
                  optionBtn
                  enableMoveButton={true}
                  handleAddClick={handleAddComponent}
                />
              ))}
            </div>
          </div>
        </div>

        <div className={`col-3`}>
          <div className={`${Style.box} pro-h-100`}>
            <div
              className={`pro-d-flex pro-justify-between pro-items-center ${Style.title_wrap}`}
            >
              <h6 className="pro-mb-0 pro-ttl h6">Order Components</h6>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div
                    className={`characters ${Style.items}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {characters &&
                      characters?.map((item, index) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={`item-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className={`${Style.card_wrap}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => handleCardClick(item, index)}
                              >
                                <PositionCard
                                  componentData={item}
                                  optionBtn
                                  cardIndex={index}
                                  isActive={cardActive === index}
                                  enableDeleteButton={true}
                                  setCardActive={setCardActive}
                                  position={`position - ${index + 1}`}
                                  handleDeleteClick={() =>
                                    handleDeleteModal(item, index)
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        <div className={`col-6`}>
          <div className={`${Style.box} pro-h-100`}>
            <div
              className={`pro-d-flex pro-justify-between pro-items-center ${Style.title_wrap}`}
            >
              <h6 className="pro-mb-0 pro-ttl h6">Update Component Data</h6>
              {/* <button className={`pro-btn pro-p-1 ${Style.option_btn}`}>
                  <span className="material-symbols-outlined"> more_vert </span>
                </button> */}
            </div>
            <ComponentForm
              formik={formik}
              characters={characters}
              getFieldError={getFieldError}
              basicData={basicData}
            />
            {/* <div className={`${Style.card_wrap}`}>
            {[...Array(5)].map((_, i) => (
              <PositionCard position={`position - ${i + 1}`} isCheckbox />
            ))}
          </div> */}
          </div>
        </div>
      </div>
      <ModalLayout
        show={cmsState?.showDeleteModal}
        handleClose={handleDeleteModal}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure to delete the selected ${cmsState.selectedComponent} component ?`}
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleDeleteModal}
            submitText={"Yes"}
            submitAction={handleDeleteComponent}
            isRight={true}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default PositionDrag;
