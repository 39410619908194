import style from "./dashboardCard.module.scss";
const DashboardCard = ({ title, data, icon, active, transactions }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner}`}>
        <div
          className={`${style.body} ${
            Object.keys(data)?.includes("price_total") && style.first
          }`}
        >
          <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
            {data?.total_order !== null && data?.total_order !== undefined
              ? data?.total_order
              : data?.total_payment !== null &&
                data?.total_payment !== undefined
              ? data?.total_payment
              : null}
          </h3>
          <div className="pro-d-flex pro-items-center pro-gap-2">
            <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
              {data?.month ? `${title}(${data?.month}) ` : title}
            </h6>
            {data?.percentage_difference !== "" && data?.comparison_status && (
              <span
                className={`${
                  data?.comparison_status === "greater"
                    ? style.increment
                    : data?.comparison_status === "lesser"
                    ? style.decrement
                    : ""
                } ${
                  style.growth_percentage
                } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
              >
                <span className={`material-symbols-outlined ${style.arrow}`}>
                  arrow_outward
                </span>
                <span>{data?.percentage_difference}%</span>
              </span>
            )}
          </div>
          <div
            className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
          >
            {data?.percentage_difference !== "" && data?.comparison_status && (
              <span className={`pro-badge  ${style.last_month}`}>
                {title === "Yearly Orders"
                  ? "Last Year"
                  : title === "Monthly Orders"
                  ? "Last Month"
                  : title === "Weekly Orders"
                  ? "Last Week"
                  : title === "Daily Orders"
                  ? "Yesterday"
                  : title === "Payment History"
                  ? "Last Month"
                  : title === "Total Orders"
                  ? "Last Year"
                  : title === "Today Orders"
                  ? "Yesterday"
                  : ""}
                &nbsp;:&nbsp;{data?.previous_order ?? ""}
              </span>
            )}
          </div>
        </div>
        {Object.keys(data)?.includes("price_total") && (
          <div className={`${style.body}`}>
            <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
              {data?.price_total !== null && data?.price_total !== undefined
                ? data?.price_total
                : data?.total_payment !== null &&
                  data?.total_payment !== undefined
                ? data?.total_payment
                : null}
            </h3>
            <div className="pro-d-flex pro-items-center pro-gap-2">
              <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                {Object.keys(data)?.includes("price_total")
                  ? "Total Amount"
                  : ""}
              </h6>
              {data?.price_percentage_difference !== "" &&
                data?.price_comparison_status && (
                  <span
                    className={`${
                      data?.price_comparison_status === "greater"
                        ? style.increment
                        : data?.price_comparison_status === "lesser"
                        ? style.decrement
                        : ""
                    } ${
                      style.growth_percentage
                    } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                  >
                    <span
                      className={`material-symbols-outlined ${style.arrow}`}
                    >
                      arrow_outward
                    </span>
                    <span>{data?.price_percentage_difference}%</span>
                  </span>
                )}
            </div>
            <div
              className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
            >
              {" "}
              {data?.price_percentage_difference !== "" &&
                data?.price_comparison_status && (
                  <span className={`pro-badge ${style.last_month}`}>
                    {title === "Yearly Orders"
                      ? "Last Year"
                      : title === "Monthly Orders"
                      ? "Last Month"
                      : title === "Weekly Orders"
                      ? "Last Week"
                      : title === "Daily Orders"
                      ? "Yesterday"
                      : title === "Payment History"
                      ? "Last Month"
                      : title === "Total Orders"
                      ? "Last Year"
                      : title === "Today Orders"
                      ? "Yesterday"
                      : ""}
                    &nbsp;:&nbsp;{data?.previous_price_total ?? ""}
                  </span>
                )}
            </div>
          </div>
        )}

        <div className={`${style.icon}`}>
          <span className="material-symbols-outlined x4">{icon}</span>
        </div>
      </div>
    </div>
  );
};
export default DashboardCard;
