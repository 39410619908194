import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";
import { getAuthAxiosInstance } from "../../../../api/Authentication";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  subAdminData: [],
  phoneVerified: {
    verified: false,
    status: false,
  },
  imagePreview: "",
  imageName: "",
  editAdminData: {},
  isProfileViewVisible: false,
  statusData: {},
};

export const getSubAdminEditData = createAsyncThunk(
  "/admin/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/admin/edit?user_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const passwordChange = createAsyncThunk(
  "/admin/change-password",
  async (id, { rejectWithValue }) => {
    const api = await getAuthAxiosInstance();
    try {
      const response = await api.post(`/admin/change-password`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subAdminSlice = createSlice({
  name: "subAdmin",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubAdminEditData.pending, (state) => {
        state.isLoading = true;
        // state.editAdminData = {};
        state.error = null;
      })
      .addCase(getSubAdminEditData.fulfilled, (state, action) => {
        state.editAdminData = action.payload?.data;
      })
      .addCase(getSubAdminEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = subAdminSlice.actions;
export default subAdminSlice.reducer;
