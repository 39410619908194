import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useAdmin = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Admin",
      link: "/admin/admin-user",
      icon: (
        <span className="material-symbols-outlined x4">
          admin_panel_settings
        </span>
      ),
      active: checkIfActiveRoute("/admin/admin-user", true),
    },
    {
      title: "Roles",
      link: "/admin/roles",
      icon: <span className="material-symbols-outlined x4">task</span>,
      active: checkIfActiveRoute("/admin/roles"),
    },
    {
      title: "Store",
      link: "/admin/store",
      icon: <span className="material-symbols-outlined x4">storefront</span>,
      active: checkIfActiveRoute("/admin/store", true),
    },
    {
      title: "Category",
      link: "/admin/category",
      icon: <span className="material-symbols-outlined x4">category</span>,
      active: checkIfActiveRoute("/admin/category", true),
    },
    {
      title: "Tier",
      link: "/admin/tier",
      icon: <span className="material-symbols-outlined x4">storefront</span>,
      active: checkIfActiveRoute("/admin/tier", true),
    },
    {
      title: "Orders",
      link: "/admin/orders",
      icon: (
        <span className="material-symbols-outlined x4">playlist_add_check</span>
      ),
      active: checkIfActiveRoute("/admin/orders", true),
    },
    {
      title: "Activity Log",
      link: "/admin/activity",
      icon: <span className="material-symbols-outlined x4">activity_zone</span>,
      active: checkIfActiveRoute("/admin/activity", true),
    },
    {
      title: "Settings",
      link: "/admin/settings",
      icon: <span className="material-symbols-outlined x4">settings</span>,
      active: checkIfActiveRoute("/admin/settings", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.(
      (menu) =>
        menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
      // menu.menu_name,
      // menu.name,
      // ...menu.permissions?.map((side_menu) => side_menu.name),
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useAdmin;
