import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";

const useDashboardListing = () => {
  // const dispatch = useDispatch();
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }

    //eslint-disable-next-line
  }, [isSuccess]);

  const handleTotalOrders = () => {
    setListingType("TotalOrders");
  };
  const handleNewOrders = () => {
    setListingType("NewOrders");
  };
  const handlePaymentHistory = () => {
    setListingType("PaymentHistory");
  };

  let object = {
    TotalOrders: handleTotalOrders,
    NewOrders: handleNewOrders,
    PaymentHistory: handlePaymentHistory,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    TotalOrders: {
      label: "TotalOrders",
      title: "Total Orders",
      icon: "playlist_add_check",
      handleClick,
      // component: <Messages dashboard={true} />,
    },
    NewOrders: {
      label: "NewOrders",
      title: "Today Orders",
      icon: "list_alt_add",
      handleClick,
      // component: <Members dashboard={true} />,
    },
    PaymentHistory: {
      label: "PaymentHistory",
      title: "Payment History",
      icon: "paid",
      handleClick,
      // component: <Deleted dashboard={true} />,
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
    isSuccess,
  };
};

export default useDashboardListing;
