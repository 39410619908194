import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  userListData: [],
  activeId: 1,
  excelSendModal: false,
  totalOrderCount: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = userSlice.actions;
export default userSlice.reducer;
