import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useProductVariants from "./useProductVariants";
// import { capitalizeOnSpace } from "../../../utils/Functions/table";
import FileUpload from "../../../../../Global/FileUpload";
import Select from "react-select";
import Assets from "../../../../../../assets/Assets";

const ProductVariants = ({
  data,
  index,
  getFieldError,
  formik,
  productBasicData,
}) => {
  const {
    variantImage,
    handleImageVariantPreviewChange,
    handleImageVariantPreviewRemove,
    handleImageVariantCustomizedPreviewChange,
    handleImageVariantCustomizedPreviewRemove,
  } = useProductVariants(formik, data, productBasicData);

  return (
    <>
      <div className="pro-d-flex pro-items-center">
        <h6 className="pro-font-sm pro-fw-bolder pro-mb-0">{`Variant (${
          data?.posist_name == "" || data?.posist_name == null
            ? index + 1
            : data?.posist_name
        })`}</h6>
      </div>
      <div className="row pro-mt-4" key={index}>
        <div className="col-6">
          <div className="input-wrap">
            <Input
              label={" Name"}
              type="text"
              id="name"
              name="name"
              className={`pro-input lg ${
                formik.touched?.variants?.[index]?.name &&
                formik.errors?.variants?.[index]?.name &&
                " error"
              }`}
              {...formik.getFieldProps(`variants.${index}.name`)}
              error={
                formik.touched?.variants?.[index]?.name &&
                formik.errors?.variants?.[index]?.name && (
                  <span className="error-text">
                    {formik.errors?.variants?.[index]?.name}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.variants?.[index]?.name &&
                formik.errors?.variants?.[index]?.name && (
                  <span className="error-text">
                    {formik.errors?.variants?.[index]?.name}
                  </span>
                )
              }
            />
          </div>
        </div>
        <div className="col-6">
          <div className="input-wrap pro-rtl">
            <Input
              label={"(اسم)Name"}
              type="text"
              id="alias_name"
              name="alias_name"
              className={`pro-input lg ${
                formik.touched?.variants?.[index]?.alias_name &&
                formik.errors?.variants?.[index]?.alias_name &&
                " error"
              }`}
              {...formik.getFieldProps(`variants.${index}.alias_name`)}
              error={
                formik.touched?.variants?.[index]?.alias_name &&
                formik.errors?.variants?.[index]?.alias_name && (
                  <span className="error-text">
                    {formik.errors?.variants?.[index]?.alias_name}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.variants?.[index]?.alias_name &&
                formik.errors?.variants?.[index]?.alias_name && (
                  <span className="error-text">
                    {formik.errors?.variants?.[index]?.alias_name}
                  </span>
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="input-wrap">
          <Input
            label={"Price"}
            type="text"
            id="price"
            name="price"
            className={`pro-input lg ${
              formik.touched?.variants?.[index]?.price &&
              formik.errors?.variants?.[index]?.price &&
              " error"
            }`}
            {...formik.getFieldProps(`variants.${index}.price`)}
            error={
              formik.touched?.variants?.[index]?.price &&
              formik.errors?.variants?.[index]?.price && (
                <span className="error-text">
                  {formik.errors?.variants?.[index]?.price}
                </span>
              )
            }
            errorMessage={
              formik.touched?.variants?.[index]?.price &&
              formik.errors?.variants?.[index]?.price && (
                <span className="error-text">
                  {formik.errors?.variants?.[index]?.price}
                </span>
              )
            }
          />
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap col">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type
          </label>
          <Select
            id="type_id"
            name="type_id"
            placeholder={"Select"}
            className={`pro-input lg ${
              formik?.touched?.variants?.[index]?.type_id &&
              formik?.errors?.variants?.[index]?.type_id &&
              " error"
            }`}
            isClearable={true}
            classNamePrefix="pro-input"
            options={productBasicData?.data?.type}
            value={productBasicData?.data?.type?.filter(
              (item) => item?._id === formik?.values?.variants?.[index]?.type_id
            )}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            onBlur={formik.handleBlur(`variants.${index}.type_id`)}
            onChange={(value) =>
              formik?.setFieldValue(
                `variants.${index}.type_id`,
                value?._id || null
              )
            }
            isMulti={false}
            menuPlacement="auto"
          />
          {formik?.touched?.variants?.[index]?.type_id &&
            formik?.errors?.variants?.[index]?.type_id && (
              <span className="error-text">
                {formik?.errors?.variants?.[index]?.type_id}
              </span>
            )}{" "}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description
          </label>
          <textarea
            id="description"
            rows={3}
            name="description"
            className={`pro-input lg ${
              formik.touched?.variants?.[index]?.description &&
              formik.errors?.variants?.[index]?.description &&
              " error"
            }`}
            value={formik?.values?.variants?.[index]?.description}
            onBlur={formik?.handleBlur(`variants.${index}.description`)}
            onChange={(e) =>
              formik.setFieldValue(
                `variants.${index}.description`,
                e.target.value
              )
            }
          ></textarea>
          {formik.touched?.variants?.[index]?.description &&
            formik.errors?.variants?.[index]?.description && (
              <span className="error-text">
                {formik.errors?.variants?.[index]?.description}
              </span>
            )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="pro-rtl">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              ( وصف ) Description
            </label>
            <textarea
              id="alias_description"
              rows={3}
              name="alias_description"
              className={`pro-input lg ${
                formik.touched?.variants?.[index]?.alias_description &&
                formik.errors?.variants?.[index]?.alias_description &&
                " error"
              }`}
              value={formik?.values?.variants?.[index]?.alias_description}
              onBlur={formik?.handleBlur(`variants.${index}.alias_description`)}
              onChange={(e) =>
                formik.setFieldValue(
                  `variants.${index}.alias_description`,
                  e.target.value
                )
              }
            ></textarea>
            {formik.touched?.variants?.[index]?.alias_description &&
              formik.errors?.variants?.[index]?.alias_description && (
                <span className="error-text">
                  {formik.errors?.variants?.[index]?.alias_description}
                </span>
              )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="input-wrap pro-mb-4">
            <FileUpload
              label={`Image (Caribou)`}
              errorStyle={
                formik.errors.variants?.[index]?.add_on_image &&
                formik.touched.variants?.[index]?.add_on_image
              }
              isDeletable={false}
              formik={formik}
              logoPreview={
                variantImage[index]?.add_on_image == "" ||
                variantImage[index]?.add_on_image == null
                  ? Assets.HEADERLOGO
                  : variantImage[index]?.add_on_image
              }
              handleLogoChange={(e) =>
                handleImageVariantPreviewChange(e, index)
              }
              handleLogoRemove={() => handleImageVariantPreviewRemove(index)}
              getFieldError={getFieldError}
            />
            {formik.touched.variants?.[index]?.add_on_image &&
              formik.errors.variants?.[index]?.add_on_image && (
                <span className="error-text">
                  {formik.errors.variants?.[index]?.add_on_image}
                </span>
              )}
          </div>
        </div>

        <div className="col-6">
          <div className="input-wrap pro-mb-4 pro-rtl">
            <FileUpload
              label={`Image (Customized)`}
              errorStyle={
                formik.errors.variants?.[index]?.image_url &&
                formik.touched.variants?.[index]?.image_url
              }
              isDeletable={true}
              formik={formik}
              logoPreview={
                variantImage[index]?.image == "" ||
                variantImage[index]?.image == null
                  ? ""
                  : variantImage[index]?.image
              }
              handleLogoChange={(e) =>
                handleImageVariantCustomizedPreviewChange(e, index)
              }
              handleLogoRemove={() =>
                handleImageVariantCustomizedPreviewRemove(index)
              }
              getFieldError={getFieldError}
            />
            {formik.touched.variants?.[index]?.image_url &&
              formik.errors.variants?.[index]?.image_url && (
                <span className="error-text">
                  {formik.errors.variants?.[index]?.image_url}
                </span>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductVariants;
