import { useEffect, useMemo } from "react";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";
import { useLocation, useNavigate } from "react-router-dom";
import useRoutes from "../../../routes/useRoutes";

const useCommonLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const globalState = useSelector((state) => state.global);
  const { menuDrawer } = useSelector((state) => state.global);
  const { checkIfActiveRoute } = useRouteUtils();

  const {
    allowedAdminComponent,
    allowedStaffComponent,
    allowedDashboardComponent,
    allowedContentComponent,
    allowedUserComponent,
  } = useRoutes();

  useEffect(() => {
    if (
      globalState?.isLogged === true &&
      (allowedDashboardComponent?.length > 0 ||
        allowedAdminComponent?.length > 0 ||
        allowedStaffComponent?.length > 0 ||
        allowedContentComponent?.length > 0 ||
        allowedUserComponent?.length > 0)
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedAdminComponent?.length > 0) {
        navigate(`/${allowedAdminComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedStaffComponent?.length > 0) {
        navigate(`/${allowedStaffComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedContentComponent?.length > 0) {
        navigate(`/${allowedContentComponent?.map((item) => item?.path)?.[0]}`);
      } else {
        navigate(`/${allowedUserComponent?.map((item) => item?.path)?.[0]}`);
      }
    }

    //eslint-disable-next-line
  }, [
    allowedDashboardComponent,
    allowedAdminComponent,
    allowedUserComponent,
    allowedStaffComponent,
    allowedContentComponent,
  ]);

  const navigations = [
    {
      label: "Dashboard",
      link: "/dashboard",
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Admin",
      link: `/admin/${allowedAdminComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/admin"),
    },
    {
      label: "User",
      link: `/user/${allowedUserComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/user"),
    },
    {
      label: "Staff",
      link: `/staff/${allowedStaffComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/staff"),
    },
    {
      label: "CMS",
      link: `/cms/${allowedContentComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/cms"),
    },
  ];
  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  const topMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) => [menu.menu_name]);

    return navigations
      .filter((menu) => menus?.includes?.(menu?.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    handleGoBack,
    globalState,
    menuDrawer,
  };
};

export default useCommonLayout;
