import {
  DataContainer,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  SimpleReport,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useOrderList from "./useOrderList";
import EmptyData from "../../Global/EmptyData";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import OrderCardListing from "./OrderCardListing";
import Style from "./orderList.module.scss";
import Filter from "./Filter";
import SendExcelData from "./SendExcelData";

const OrderList = () => {
  const {
    date,
    isLoading,
    isFetching,
    ordersList,
    ordersState,
    showEditModal,
    headerDetails,
    isExcelLinkSend,
    paginationOptions,
    isDateRangeApplied,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleExcelDownload,
    handleFilterAction,
    updateOrdersFields,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleSort,
    modalClose,
    refetch,
    getRow,
  } = useOrderList();

  return (
    <>
      <HeadingGroup title="Orders" className={`pro-mb-4`}>
        <button
          className={`pro-btn pro-btn-outline-primary pro-items-center pro-ms-3`}
          type="submit"
          onClick={handleExcelDownload}
        >
          <span className="material-symbols-outlined x4">download</span>
          <span>{`Export Data`}</span>
        </button>
      </HeadingGroup>
      <OrderCardListing />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className={`${isFetching ? "col" : "col-auto"}`}>
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                ordersState?.search !== "" || isDateRangeApplied ? true : false
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: ordersState?.search }}
              handleActionClick={handleEditColumnsClick}
              dropDownFilter={
                <>
                  <div className="col-auto">
                    <CustomDateRangePicker
                      defaultDateRange={date}
                      handleDateRangeChange={handleDateRangeChange}
                    />
                  </div>
                </>
              }
              onDateChange={handleDateChange}
              initialDateRange={date}
            />
          </div>
          {!isFetching && (
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
                onClick={handleFilterAction}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          )}
        </div>
        {ordersList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={ordersList?.data?.result || []}
            uniqueID={"id"}
            fields={ordersList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={ordersState?.clearSelection}
            perpage={ordersState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}
        {ordersList?.data?.result?.length !== 0 && !isFetching ? (
          <div className="pro-m-4 pro-mx-0">
            <DataContainer>
              <SimpleReport
                extraClassNames={Style.simple_report}
                propStyle={{ textInner: Style.textInner }}
                data={headerDetails}
              />
            </DataContainer>
          </div>
        ) : (
          <></>
        )}

        {ordersList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={ordersState?.currentPage}
            totalPageCount={Math.ceil(
              ordersState.totalOrderCount / ordersState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === ordersState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={ordersList?.data?.fields || []}
              moduleId={ordersList?.data?.module_id}
              updateData={updateOrdersFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={ordersState.filterShow}
          handleClose={handleFilterAction}
          title={`Apply Filter`}
          backdrop="static"
        >
          <Filter />
        </OffCanvasLayout>
        <ModalLayout
          show={ordersState.excelSendModal}
          handleClose={handleExcelDownload}
          title={`Send Excel Download Link`}
          backdrop="static"
        >
          <div className="pro-m-5">
            <SendExcelData
              handleCloseModal={modalClose}
              sendExcelData={sendExcelData}
              isExcelLinkSend={isExcelLinkSend}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default OrderList;
