import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Admin/Store/storeSlice";
import { getProfileImage } from "../api";

const useBasic = (formik) => {
  const dispatch = useDispatch();
  const { isEdit, imagePreview, imageName } = useSelector(
    (state) => state.store
  );

  const handleImageChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      getProfileImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            "image_url",
            response?.data?.data?.image_path ?? ""
          );
        }
      });
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Store Hours";
      })
    );
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  return {
    isEdit,
    imagePreview,
    handleNext,
    handleCloseModal,
    handleImageChange,
    imageName,
  };
};

export default useBasic;
