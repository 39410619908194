import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Admin/Store/storeSlice";
import dayjs from "dayjs";

const useCurbSide = (formik) => {
  const [enabledDays, setEnabledDays] = useState({});
  const [time, setTime] = useState({
    from: {},
    to: {},
  });

  const dispatch = useDispatch();
  const { isEdit, curbTimeError } = useSelector((state) => state.store);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    if (formik.values.curb_side !== 1) {
      formik.setFieldValue("curbside_hours", {});
      dispatch(
        updateConfig((state) => {
          state.curbTimeError = {};
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.curb_side]);

  useEffect(() => {
    // Extract values from formik and set in the time state
    const extractedTime = {};

    daysOfWeek?.forEach((day) => {
      if (
        formik?.values?.curbside_hours &&
        formik?.values?.curbside_hours[day]
      ) {
        extractedTime[day] = {
          from: dayjs(formik?.values?.curbside_hours[day]?.from, "h:mm A"),
          to: dayjs(formik?.values?.curbside_hours[day]?.to, "h:mm A"),
        };
      } else {
        extractedTime[day] = {
          from: null,
          to: null,
        };
      }
    });

    setTime(extractedTime);

    // Enable toggles for days with a time, otherwise disable
    const enabledDaysFromExtractedTime = {};
    daysOfWeek.forEach((day) => {
      enabledDaysFromExtractedTime[day] =
        !!extractedTime[day]?.from && !!extractedTime[day]?.to;
    });
    setEnabledDays(enabledDaysFromExtractedTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.curbside_hours]);

  const handleTimeChange = (day, type, newValue) => {
    const updatedTimeState = { ...time };
    updatedTimeState[day][type] = newValue;

    if (updatedTimeState[day].from && updatedTimeState[day].to) {
      const fromTime = updatedTimeState[day].from;
      const toTime = updatedTimeState[day].to;

      // Check if "to" time is at least 1 hour after "from" time
      const timeDifferenceInMinutes = toTime.diff(fromTime, "minutes");
      const isError = timeDifferenceInMinutes < 60;

      // Update the error state for this day
      dispatch(
        updateConfig((state) => {
          state.curbTimeError = {
            ...state.curbTimeError,
            [day]: isError
              ? "To time should be at least 1 hour after from time"
              : null,
          };
        })
      );

      const updatedCurbsideHours = {
        ...formik?.values.curbside_hours,
        [day]: {
          from: fromTime.format("h:mm A"),
          to: toTime.format("h:mm A"),
        },
      };

      formik.setFieldValue("curbside_hours", updatedCurbsideHours);
    }

    setTime(updatedTimeState);
  };

  const handleToggle = (day) => {
    setEnabledDays((prevEnabledDays) => {
      const updatedEnabledDays = {
        ...prevEnabledDays,
        [day]: !prevEnabledDays[day],
      };

      // Remove day and its time if toggle is disabled
      if (!updatedEnabledDays[day]) {
        const updatedCurbsideHours = { ...formik?.values.curbside_hours };
        delete updatedCurbsideHours[day];
        formik.setFieldValue("curbside_hours", updatedCurbsideHours);

        // Check if the day has no errors, and if so, remove it from the curbTimeError

        dispatch(
          updateConfig((state) => {
            state.curbTimeError = {
              ...state.curbTimeError,
              [day]: null,
            };
          })
        );
      }

      return updatedEnabledDays;
    });
  };

  const getMinToTime = (day, fromTime) => {
    if (fromTime) {
      return fromTime.add(1, "hour");
    }
    return null;
  };

  return {
    isEdit,
    time,
    handleCloseModal,
    daysOfWeek,
    handleTimeChange,
    handleToggle,
    enabledDays,
    getMinToTime,
    curbTimeError,
  };
};
export default useCurbSide;
