import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";
import { useSelector } from "react-redux";
import countryData from "../../../../utils/components/countryCode";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Staff/staffSlice";
import { createAndUpdateStaff } from "../../api";
import { toast } from "react-toastify";
import {
  useGetStaffBasicDataQuery,
  useGetStaffProfileImageMutation,
} from "../../../../store/queries/Staff";
import { useGetUserDetailsDataQuery } from "../../../../store/queries/Global";

const useAddStaff = (handleCreateClick, refetch) => {
  const { emailRegExp, validPasswords } = useValidations();
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isStoreAvailable, setIsStoreAvailable] = useState(false);
  const [isStaffCreate, setIsStaffCreate] = useState(false);
  // const [showReEnterPassword, setShowReEnterPassword] = useState(true);
  const dispatch = useDispatch();

  const { isEdit, imagePreview, editStaffData, imageName } = useSelector(
    (state) => state.staff
  );
  const { data: staffBasicData = {} } = useGetStaffBasicDataQuery();
  const [uploadStaffProfileImage] = useGetStaffProfileImageMutation();
  const { data: userData = {} } = useGetUserDetailsDataQuery();

  useEffect(() => {
    if (
      userData?.data?.hasOwnProperty(`customer_key`) &&
      userData?.data?.customer_key !== "" &&
      userData?.data?.customer_key !== null
    ) {
      formik?.setFieldValue("customer_key", userData?.data?.customer_key);
      setIsStoreAvailable(true);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit && editStaffData !== "") {
      formik?.setValues({
        first_name: editStaffData?.first_name ?? "",
        last_name: editStaffData?.last_name ?? "",
        personal_number: editStaffData?.personal_number ?? "",
        contact_number: editStaffData?.contact_number ?? "",
        email: editStaffData?.email ?? "",
        customer_key: editStaffData?.customer_key ?? "",
        dialcode: editStaffData?.dialcode ?? "",
        password_change: editStaffData?.password_change ?? 2,
        password_confirmation: editStaffData?.password_confirmation ?? "",
        status: editStaffData?.status ?? 2,
        image: editStaffData?.image ?? "",
      });

      dispatch(
        updateConfig((state) => {
          state.imagePreview = editStaffData?.image_url;
          state.imageName = editStaffData?.image?.split("/").pop();
        })
      );
    }
    //eslint-disable-next-line
  }, [isEdit, editStaffData]);

  const validation = Yup.object({
    first_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter First Name")
      .max(50, "Enter valid Name"),
    last_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter Last Name")
      .max(50, "Enter valid Name"),
    dialcode: Yup.string().required("Select Country Code"),
    personal_number: Yup.string()
      .required("Enter Personal Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number"),
    contact_number: Yup.string()
      .required("Enter Contact Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number"),
    email: Yup.string()
      .required("Enter  Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
    customer_key: Yup.string().required("Select store"),
    password:
      (!isEdit || isPasswordChange) &&
      Yup.string()
        .required("Enter Password")
        .min(
          8,
          "Password must be 8 characters or more and include uppercase, numbers, and symbols"
        )
        .test(
          "Password validation",
          "*Password must be 8 characters or more and include uppercase, numbers, and symbols.",
          function (passwordValue) {
            const validationResults = validPasswords(passwordValue);
            if (
              validationResults?.uppercasePassword &&
              validationResults?.lowercasePassword &&
              validationResults?.digitsPassword &&
              validationResults?.charsPassword
            ) {
              return true;
            }
          }
        ),
    password_confirmation:
      editStaffData?.password_change === 1 &&
      Yup.string()
        .required("Enter Password")
        .min(
          8,
          "Password must be 8 characters or more and include uppercase, numbers, and symbols"
        )
        .test(
          "Password Change validation",
          "*Password must be 8 characters or more and include uppercase, numbers, and symbols.",
          function (passwordChangeValue) {
            const validationResults = validPasswords(passwordChangeValue);
            if (
              validationResults?.uppercasePassword &&
              validationResults?.lowercasePassword &&
              validationResults?.digitsPassword &&
              validationResults?.charsPassword
            ) {
              return true;
            }
          }
        ),
    password_change: editStaffData?.password_change === 1 && Yup.string(),
    image: Yup.string().required("Upload Image"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      dialcode: "+965",
      personal_number: "",
      contact_number: "",
      email: "",
      customer_key: "",
      password_change: 2,
      password: "",
      password_confirmation: "",
      status: 2,
      image: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      setIsStaffCreate(true);
      let data = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        dialcode: values?.dialcode,
        personal_number: values?.personal_number,
        contact_number: values?.contact_number,
        email: values?.email,
        customer_key: values?.customer_key,
        password_change: values?.password_change ?? "",
        password: values?.password,
        password_confirmation: values?.password_confirmation ?? "",
        status: values?.status,
        image: values?.image,
      };
      let newObj = Object.entries(data);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      if (!isEdit) {
        createAndUpdateStaff(subData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Staff created successfully`);
            setIsStaffCreate(false);
            handleCreateClick();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (
            response?.data?.error_code === 403 &&
            response?.data?.errors
          ) {
            Object.keys(response.data.errors).forEach((field) => {
              formik.setFieldError(field, response.data.errors[field]);
            });
            setIsStaffCreate(false);
          } else {
            toast.error(`Something went wrong!`);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            setIsStaffCreate(false);
            handleCreateClick();
          }
        });
      } else {
        let updateData = { staff_id: editStaffData?._id, ...subData };
        createAndUpdateStaff(updateData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Staff updated successfully`);
            setIsStaffCreate(false);
            handleCreateClick();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (
            response?.data?.error_code === 403 &&
            response?.data?.errors
          ) {
            Object.keys(response.data.errors).forEach((field) => {
              formik.setFieldError(field, response.data.errors[field]);
            });
            setIsStaffCreate(false);
          } else {
            toast.error(`Something went wrong!`);
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            setIsStaffCreate(false);
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const countryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );
  const handleImageChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      // setLastUploadedLogo(selectedLogo);
      uploadStaffProfileImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue("image", response?.data?.data?.image_path ?? "");
        }
      });
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault();

    setNewShowPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  let countryObj = countryCodeOptions?.filter(
    (item) => item?.value === formik?.values?.dialcode
  );
  let roleObj = staffBasicData?.data?.stores?.filter(
    (item) => item?.customer_key === formik?.values?.customer_key
  );

  return {
    formik,
    isEdit,
    roleObj,
    imageName,
    countryObj,
    imagePreview,
    isStaffCreate,
    showNewPassword,
    isStoreAvailable,
    countryCodeOptions,
    handleShowNewPassword,
    setIsPasswordChange,
    handleImageChange,
    getFieldError,
    closeModal,
    basicData: staffBasicData?.data?.stores,
  };
};

export default useAddStaff;
