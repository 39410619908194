import React from "react";
import PositionDrag from "./PositionDrag";
import { HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import useHomePage from "./useHomePage";

const HomePage = () => {
  const {
    formik,
    characters,
    componentBasicData,
    handleUpdateHomePage,
    getFieldError,
  } = useHomePage();
  return (
    <>
      <HeadingGroup title="Home Page" className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <PositionDrag
          formik={formik}
          getFieldError={getFieldError}
          basicData={componentBasicData}
          characters={characters}
          handleUpdateHomePage={handleUpdateHomePage}
        />
      </div>
    </>
  );
};

export default HomePage;
