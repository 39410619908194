import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
// import { capitalizeOnSpace } from "../../../utils/Functions/table";
import useProductAddOns from "./useProductAddOns";
import FileUpload from "../../../../../Global/FileUpload";
import Select from "react-select";
import Assets from "../../../../../../assets/Assets";

const ProductAddOns = ({ data, index, formik, getFieldError, addOnKey }) => {
  const {
    addOnImage,
    optionalAddOnImage,
    mappedVariantListOptions,
    handleImageAddOnPreviewChange,
    handleImageAddOnPreviewRemove,
    handleImageAddOnCustomizedPreviewRemove,
    handleImageAddOnCustomizedPreviewChange,
  } = useProductAddOns(formik, index);
  return (
    <>
      <div className="pro-d-flex pro-items-center">
        <h6 className="pro-font-sm pro-fw-bolder pro-mb-0">{`${
          addOnKey === "add_on" ? "Product AddOns" : "Optional Product Add On"
        } (${
          data?.posist_name == "" || data?.posist_name == null
            ? index + 1
            : data?.posist_name
        })`}</h6>
      </div>
      <div className="" key={index}>
        <div className="col-12 pro-mt-4">
          <div className="row gx-3">
            <div className="col-6">
              <div className="input-wrap">
                <Input
                  label={" Name"}
                  type="text"
                  id="name"
                  name="name"
                  className={`pro-input lg ${
                    formik.touched?.[addOnKey]?.[index]?.name &&
                    formik.errors?.[addOnKey]?.[index]?.name &&
                    " error"
                  }`}
                  {...formik.getFieldProps(`${addOnKey}.${index}.name`)}
                  error={
                    formik.touched?.[addOnKey]?.[index]?.name &&
                    formik.errors?.[addOnKey]?.[index]?.name && (
                      <span className="error-text">
                        {formik.errors?.[addOnKey]?.[index]?.name}
                      </span>
                    )
                  }
                  errorMessage={
                    formik.touched?.[addOnKey]?.[index]?.name &&
                    formik.errors?.[addOnKey]?.[index]?.name && (
                      <span className="error-text">
                        {formik.errors?.[addOnKey]?.[index]?.name}
                      </span>
                    )
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="pro-rtl">
                <div className="input-wrap">
                  <Input
                    label={"(اسم)Name"}
                    type="text"
                    id="alias_name"
                    name="alias_name"
                    className={`pro-input lg ${
                      formik.touched?.[addOnKey]?.[index]?.alias_name &&
                      formik.errors?.[addOnKey]?.[index]?.alias_name &&
                      " error"
                    }`}
                    {...formik.getFieldProps(`${addOnKey}.${index}.alias_name`)}
                    error={
                      formik.touched?.[addOnKey]?.[index]?.alias_name &&
                      formik.errors?.[addOnKey]?.[index]?.alias_name && (
                        <span className="error-text">
                          {formik.errors?.[addOnKey]?.[index]?.alias_name}
                        </span>
                      )
                    }
                    errorMessage={
                      formik.touched?.[addOnKey]?.[index]?.alias_name &&
                      formik.errors?.[addOnKey]?.[index]?.alias_name && (
                        <span className="error-text">
                          {formik.errors?.[addOnKey]?.[index]?.alias_name}
                        </span>
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-wrap">
          <Input
            label={"Price"}
            type="text"
            id="price"
            name="price"
            className={`pro-input lg ${
              formik.touched?.[addOnKey]?.[index]?.price &&
              formik.errors?.[addOnKey]?.[index]?.price &&
              " error"
            }`}
            {...formik.getFieldProps(`${addOnKey}.${index}.price`)}
            error={
              formik.touched?.[addOnKey]?.[index]?.price &&
              formik.errors?.[addOnKey]?.[index]?.price && (
                <span className="error-text">
                  {formik.errors?.[addOnKey]?.[index]?.price}
                </span>
              )
            }
            errorMessage={
              formik.touched?.[addOnKey]?.[index]?.price &&
              formik.errors?.[addOnKey]?.[index]?.price && (
                <span className="error-text">
                  {formik.errors?.[addOnKey]?.[index]?.price}
                </span>
              )
            }
          />
        </div>
        <div className="col-12 pro-mb-4">
          <div className="input-wrap pro-mb-4 col">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mapped Variants
            </label>
            <Select
              id="parent_id"
              name="parent_id"
              isClearable={true}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik?.touched?.[addOnKey]?.[index]?.parent_id &&
                formik?.errors?.[addOnKey]?.[index]?.parent_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={mappedVariantListOptions}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              value={mappedVariantListOptions?.filter((m) =>
                formik?.values?.[addOnKey]?.[index]?.parent_id?.includes?.(
                  m?.value
                )
              )}
              onBlur={formik.handleBlur(`${addOnKey}.${index}.parent_id`)}
              onChange={(value) => {
                formik.setFieldValue(
                  `${addOnKey}.${index}.parent_id`,
                  value.map((v) => v?.value)
                );
              }}
              isDisabled
              isMulti={true}
              menuPlacement="auto"
            />
            {formik?.touched?.[addOnKey]?.[index]?.parent_id &&
              formik?.errors?.[addOnKey]?.[index]?.parent_id && (
                <span className="error-text">
                  {formik?.errors?.[addOnKey]?.[index]?.parent_id}
                </span>
              )}{" "}
          </div>
        </div>
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
            <textarea
              id="description"
              rows={3}
              name="description"
              className={`pro-input lg ${
                formik.touched?.[addOnKey]?.[index]?.description &&
                formik.errors?.[addOnKey]?.[index]?.description &&
                " error"
              }`}
              value={formik?.values?.[addOnKey]?.[index]?.description}
              onBlur={formik?.handleBlur(`${addOnKey}.${index}.description`)}
              onChange={(e) =>
                formik.setFieldValue(
                  `${addOnKey}.${index}.description`,
                  e.target.value
                )
              }
            ></textarea>
            {formik.touched?.[addOnKey]?.[index]?.description &&
              formik.errors?.[addOnKey]?.[index]?.description && (
                <span className="error-text">
                  {formik.errors?.[addOnKey]?.[index]?.description}
                </span>
              )}
          </div>
        </div>
        <div className="col-12 pro-mb-4">
          <div className="pro-rtl">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                ( وصف ) Description
              </label>
              <textarea
                id="alias_description"
                rows={3}
                name="alias_description"
                className={`pro-input lg ${
                  formik.touched?.[addOnKey]?.[index]?.alias_description &&
                  formik.errors?.[addOnKey]?.[index]?.alias_description &&
                  " error"
                }`}
                value={formik?.values?.[addOnKey]?.[index]?.alias_description}
                onBlur={formik?.handleBlur(
                  `${addOnKey}.${index}.alias_description`
                )}
                onChange={(e) =>
                  formik.setFieldValue(
                    `${addOnKey}.${index}.alias_description`,
                    e.target.value
                  )
                }
              ></textarea>
              {formik.touched?.[addOnKey]?.[index]?.alias_description &&
                formik.errors?.[addOnKey]?.[index]?.alias_description && (
                  <span className="error-text">
                    {formik.errors?.[addOnKey]?.[index]?.alias_description}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap pro-mb-4">
              <FileUpload
                label={`Image (Caribou)`}
                errorStyle={
                  formik.errors?.[addOnKey]?.[index]?.add_on_image &&
                  formik.touched?.[addOnKey]?.[index]?.add_on_image
                }
                isDeletable={false}
                formik={formik}
                logoPreview={
                  addOnKey === "add_on"
                    ? addOnImage[index]?.add_on_image == "" ||
                      addOnImage[index]?.add_on_image == null
                      ? Assets.HEADERLOGO
                      : addOnImage[index]?.add_on_image
                    : optionalAddOnImage[index]?.add_on_image == "" ||
                      optionalAddOnImage[index]?.add_on_image == null
                    ? Assets.HEADERLOGO
                    : optionalAddOnImage[index]?.add_on_image
                }
                handleLogoChange={(e) =>
                  handleImageAddOnPreviewChange(e, index, addOnKey)
                }
                handleLogoRemove={() =>
                  handleImageAddOnPreviewRemove(index, addOnKey)
                }
                getFieldError={getFieldError}
              />
              {formik.touched?.[addOnKey]?.[index]?.add_on_image &&
                formik.errors?.[addOnKey]?.[index]?.add_on_image && (
                  <span className="error-text">
                    {formik.errors?.[addOnKey]?.[index]?.add_on_image}
                  </span>
                )}
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap pro-mb-4">
              <FileUpload
                label={`Image (Customized)`}
                errorStyle={
                  formik.errors?.[addOnKey]?.[index]?.image_url &&
                  formik.touched?.[addOnKey]?.[index]?.image_url
                }
                isDeletable={true}
                formik={formik}
                logoPreview={
                  addOnKey === "add_on"
                    ? addOnImage[index]?.image == "" ||
                      addOnImage[index]?.image == null
                      ? ""
                      : addOnImage[index]?.image
                    : optionalAddOnImage[index]?.image == "" ||
                      optionalAddOnImage[index]?.image == null
                    ? ""
                    : optionalAddOnImage[index]?.image
                }
                handleLogoChange={(e) =>
                  handleImageAddOnCustomizedPreviewChange(e, index, addOnKey)
                }
                handleLogoRemove={() =>
                  handleImageAddOnCustomizedPreviewRemove(index, addOnKey)
                }
                getFieldError={getFieldError}
              />
              {formik.touched?.[addOnKey]?.[index]?.image_url &&
                formik.errors?.[addOnKey]?.[index]?.image_url && (
                  <span className="error-text">
                    {formik.errors?.[addOnKey]?.[index]?.image_url}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductAddOns;
