import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddTier from "./useAddTier";
function AddTier({ isStickyFooter, handleClose, refetch }) {
  const { formik, getFieldError, isEdit } = useAddTier(handleClose, refetch);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={" Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${getFieldError("name") && " error"}`}
          {...formik.getFieldProps("name")}
          error={getFieldError("name")}
          errorMessage={getFieldError("name")}
        />
        <div className="pro-rtl">
          <Input
            label={"( اسم ) Name "}
            type="text"
            id="alias_name"
            name="alias_name"
            className={`pro-input lg ${
              getFieldError("alias_name") && " error"
            }`}
            {...formik.getFieldProps("alias_name")}
            error={getFieldError("alias_name")}
            errorMessage={getFieldError("alias_name")}
          />
        </div>

        <Input
          label={"Point"}
          type="text"
          id="point"
          name="point"
          className={`pro-input lg ${getFieldError("point") && " error"}`}
          {...formik.getFieldProps("point")}
          error={getFieldError("point")}
          errorMessage={getFieldError("point")}
        />
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
            <textarea
              id="description"
              rows={3}
              name="description"
              className={`pro-input lg ${
                formik.errors.description &&
                formik.touched.description &&
                " error"
              }`}
              value={formik?.values?.description}
              onBlur={formik?.handleBlur("description")}
              onChange={(e) =>
                formik.setFieldValue("description", e.target.value)
              }
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <span className="error-text">{formik.errors.description}</span>
            )}
          </div>
        </div>
        <div className="col-12 pro-mb-4">
          <div className="pro-rtl">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                ( وصف ) Description
              </label>
              <textarea
                id="alias_description"
                rows={3}
                name="alias_description"
                className={`pro-input lg ${
                  formik.errors.alias_description &&
                  formik.touched.alias_description &&
                  " error"
                }`}
                value={formik?.values?.alias_description}
                onBlur={formik?.handleBlur("alias_description")}
                onChange={(e) =>
                  formik.setFieldValue("alias_description", e.target.value)
                }
              ></textarea>
              {formik.touched.alias_description &&
                formik.errors.alias_description && (
                  <span className="error-text">
                    {formik.errors.alias_description}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="status"
            name="status"
            checked={Boolean(formik.values?.status === 1) ?? false}
            onChange={(e) => {
              formik.setFieldValue("status", e.target.checked ? 1 : 2);
            }}
          />
          <label htmlFor="status" className="pro-check-label">
            Status
          </label>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {!isEdit ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddTier;
